import React from "react";
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";
import logoSmall from "assets/images/logo-sm.png";
import { loginUser } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { createSelector } from 'reselect';
import { Link } from "react-router-dom";
import ModernImage from "../AuthenticationInner/ModernImage";
import Modern from "../AuthenticationInner/Modern";

const Login = (props: any) => {

    document.title = "Login | Smart Sales Assistance";

    const dispatch = useDispatch<any>();

    const selectLogin = createSelector(
        (state: any) => state.Register,
        (state: any) => state.Login,
        (register, login) => ({
            user: register.user,
            success: login.success,
            error: login.error
        })
    )

    const { user, success, error } = useSelector(selectLogin)

    const validation: any = useFormic({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: user.email || '',
            password: user.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values: any) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });



    return (
        <React.Fragment>
            <div className="relative flex flex-col w-full overflow-hidden xl:flex-row to-custom-800 bg-gradient-to-r from-custom-900 dark:to-custom-900 dark:from-custom-950">
                <ModernImage />
                <div className="min-h-[calc(100vh_-_theme('spacing.4')_*_2)] mx-3 xl:w-[40rem] shrink-0 px-10 py-14 flex items-center justify-center m-4 bg-white rounded z-10 relative xl:mx-4">
                    <div className="flex flex-col w-full h-full">
                        <div className="my-auto">
                            <Link to="/">
                                <img src={logoSmall} alt="" className="w-8 mx-auto" />
                            </Link>

                            <div className="mt-8 text-center">
                                <h4 className="mb-1">Welcome Back !</h4>
                                <p className="text-slate-500 dark:text-zink-200">Sign in to continue to SSA.</p>
                            </div>

                            <form className="mt-10" id="signInForm"
                                onSubmit={(event: any) => {
                                    event.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                {success && <div className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50" id="successAlert">
                                    You have <b>successfully</b> signed in.
                                </div>}
                                {error && <div className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50" id="successAlert">
                                    You have <b>failed</b> signed in.
                                </div>}
                                <div className="mb-3">
                                    <label htmlFor="email" className="inline-block mb-2 text-base font-medium">UserName/ Email ID</label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter username or email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <div id="email-error" className="mt-1 text-sm text-red-500">{validation.errors.email}</div>
                                    ) : null}

                                </div>
                                <div className="mb-3">
                                    <div className="flex items-center justify-between mb-2">
                                        <label htmlFor="password" className="inline-block text-base font-medium">Password</label>
                                        <Link to="/forgot-password" className="text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500">Forgot Password ?</Link>

                                    </div>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password || ""}
                                    />
                                    {validation.touched.password && validation.errors.password ? (
                                        <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <div className="flex items-center gap-2">
                                        <input id="checkboxDefault1" className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400" type="checkbox" value="" />
                                        <label htmlFor="checkboxDefault1" className="inline-block text-base font-medium align-middle cursor-pointer">Remember me</label>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <button type="submit" className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 ">Sign In</button>
                                </div>
                            </form>
                        </div>
                        <div className="mt-5">
                            <p className="mb-0 text-center text-base text-slate-500 dark:text-zink-200">© {new Date().getFullYear()} SSA. Crafted with <i className="text-red-500 ri-heart-fill"></i> by <a rel="noreferrer" target="_blank" href="http://baasbox.com" className="underline transition-all duration-200 ease-linear text-slate-800 dark:text-zink-50 hover:text-custom-500">Baasbox</a></p>
                        </div>
                    </div>
                </div>
                <Modern />
            </div>
        </React.Fragment>
    );
}

export default withRouter(Login);