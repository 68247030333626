import {createAsyncThunk} from "@reduxjs/toolkit";

import 'react-toastify/dist/ReactToastify.css';
import sendRequest from "helpers/sendRequest";
import {ADD_AGENT, AUTOCOMPLETE_URL_AGENT, GET_AGENT, GET_AGENTS} from "helpers/url_helper";

export const getAgents = createAsyncThunk("agents/getAgents", async () => {
    try {
        const response = await sendRequest(GET_AGENTS, { method: 'GET', }, true);;
        return response;
    } catch (error) {
        return error;
    }
});

export const addAgent = createAsyncThunk("agents/addAgent", async (event: any) => {
    try {
        const response = await sendRequest(ADD_AGENT, { method: 'POST', body: event }, true);
        console.log(response);
        return response;
    } catch (error) {
        return error;
    }
});

export const autocompleteUrlAgent = async (url: string) => {
    try {
        const response = await sendRequest(AUTOCOMPLETE_URL_AGENT + "?url=" + url, { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const getAgent = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id, { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const getAgentIntents = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/intents", { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const getAgentMaterials = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/materials", { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const updateAgentMaterials = async (id: string, content:any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/material/add-update", { method: 'POST', body: content }, true);
        const data = await response;
        return {data: data, error: null};
    } catch (error) {
        return {data: null, error: error};
    }
}

export const updateAgentIntent = async (id: string, intent_id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/intent/" + intent_id, { method: 'PUT', body: content }, true);
        return await response;
    } catch (error) {
        return error;
    }
}

export const updateAgentAction = async (id: string, action_id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/modify-action/" + action_id, { method: 'PUT', body: content }, true);
        return await response;
    } catch (error) {
        return error;
    }
}


export const updateAgentFirstMessage = async (id: string, first_message: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/first-message", { method: 'POST', body: JSON.stringify({first_message: first_message}) }, true);
        return await response;
    } catch (error) {
        return error;
    }
}


export const updateAgentPrompt = async (id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/prompt", { method: 'PUT', body: content }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const updateAgentDomain = async (id: string, content: any) => {
    return await sendRequest(GET_AGENT + "/" + id + "/domain", { method: 'POST', body: content }, true);
}

export const addAgentIntent = async (id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/intent/add", { method: 'POST', body: content }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const addAgentAction = async (id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/action", { method: 'POST', body: content }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const getAgentActions = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/actions", { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const getAgentSessions = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/list-messages", { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const deleteAgentIntent = async (id: string, intent_id: string,) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/intent/delete/" + intent_id, { method: 'DELETE' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const deleteAgentAction = async (id: string, action_id: string,) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/action/" + action_id, { method: 'DELETE' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const updateAgentImage = async (id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/update-agent-image/", { method: 'POST', body: content }, true, {});
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const deleteAgent = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/destroy", { method: 'DELETE' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const updateAgent = async (id: string, content: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id, { method: 'POST', body: content }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const getAgentLeads = async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/leads", { method: 'GET' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}

export const shutdownAgent = createAsyncThunk("agents/shutdownAgent", async (id: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/shutdown", { method: 'POST' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
});

export const turnonAgent = createAsyncThunk("agents/turnonAgent", async (id: string) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/turnon", { method: 'POST' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
});

export const createAgent = async (content: any) => {
    try {
        const response = await sendRequest(GET_AGENT, { method: 'POST',  body: JSON.stringify(content) }, true);        
        const data = await response;
        if(data.agent){
            return {data: data.agent, error: null};
        } else {
            return {data: null, error: data.message};
        }
    } catch (error) {
        return {data: null, error: error};
    }
}

export const duplicateAgent = async (id: any) => {
    try {
        const response = await sendRequest(GET_AGENT + "/" + id + "/duplicate", { method: 'POST' }, true);
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
}



/*
export const updateAgent = createAsyncThunk("agents/updateAgent", async (event: any) => {
    try {
        const response = updateAgentApi(event);
        const data = await response;
        toast.success("Agent updated Successfully", { autoClose: 2000 });
        return data;
    } catch (error) {
        toast.error("Agent updated Failed", { autoClose: 2000 });
        return error;
    }
});
export const deleteAgent = createAsyncThunk("agents/deleteAgent", async (event: any) => {
    try {
        const response = deleteAgentApi(event);
        toast.success("Agent deleted Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("Agent deleted Failed", { autoClose: 2000 });
        return error;
    }
}); */
