import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAgentIntents } from "slices/thunk";
import SingleIntent from "../Components/SingleIntent";
import CreateNewIntent from "../Components/CreateNewIntent";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";


const IntentsTabs = (props:any) => {

    const {currentAgent, setCurrentAgent} = props;

    const [status, setStatus] = useState<any>({loading: false, error: false});

    const [currentIntents, setCurrentIntents] = useState<any>(currentAgent.intents ? currentAgent.intents : null); 

    const handleFetchIntents = async () => {
        const response = await getAgentIntents(currentAgent.id);

        if(response.intents) {
            setCurrentIntents(response.intents);
            setStatus({loading: false, error: false});
        } else {
            console.log(response.error);
            toast.error("An error occurred while fetching the intents");
            setStatus({loading: false, error: true});
        }
    }

    useEffect(() => {
        if(!currentIntents) {
            setStatus({loading: true, error: false});
            handleFetchIntents();            
        }
    }, []);

    useEffect(() => {
        setCurrentAgent({...currentAgent, intents: currentIntents});            
    }, [currentIntents]);

    return (
        <React.Fragment>
            {status.error ? (
               <div>There was an error!</div>
            ) : (
                status.loading ? (
                    [...Array(8)].map((_:any, index: any) => {
                        return (
                            <div key={"skeleton-" + index} className="card">
                                <div className="card-body">
                                    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#ddd">
                                        <p>
                                            <Skeleton  width="100%" height="100%" />
                                        </p>
                                    </SkeletonTheme>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <>                        
                        {currentIntents && currentIntents.length > 0 &&  currentIntents.map((content: any, index: number) => (
                            <SingleIntent 
                                currentIntents={currentIntents} 
                                setCurrentIntents={setCurrentIntents} 
                                content={content} 
                                key={"intent-" + index}  
                            />
                        ))}

                        <CreateNewIntent 
                            currentIntents={currentIntents} 
                            setCurrentIntents={setCurrentIntents}  
                            handleFetchIntents={handleFetchIntents}
                        />
                    </>
                )
            )}
           
        </React.Fragment>
    );
}

export default IntentsTabs;