import React from "react";

import { useSelector } from "react-redux";

import { RootState } from "slices";

const ProfileTabs = () => {

    const selectProperties: any = useSelector((state: RootState) => {
        return state.Profile.user
    });


    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Update Profile</h6>
                    <form>
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-4">
                                <label htmlFor="userId" className="inline-block mb-2 text-base font-medium">User ID</label>
                                <div className="relative">
                                    <input
                                        disabled
                                        readOnly
                                        type="text"
                                        id="userId"
                                        name="user_id"
                                        className="form-input border-slate-200 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        value={selectProperties.id} />
                                </div>
                            </div>

                            <div className="xl:col-span-4">
                                <label htmlFor="userName" className="inline-block mb-2 text-base font-medium">User Name</label>
                                <div className="relative">
                                    <input
                                        disabled
                                        readOnly
                                        type="text"
                                        id="userName"
                                        name="user_name"
                                        className="form-input border-slate-200 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        value={selectProperties.name} />
                                </div>
                            </div>

                            <div className="xl:col-span-4">
                                <label htmlFor="email" className="inline-block mb-2 text-base font-medium">Email</label>
                                <div className="relative">
                                    <input
                                        disabled
                                        readOnly
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="form-input border-slate-200 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        value={selectProperties.email} />
                                </div>
                            </div>

                            <div className="flex justify-end xl:col-span-6">
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100">Update Profile</button>
                            </div>
                           
                           
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>

    );
}

export default ProfileTabs;