import React, { useEffect, useState } from "react";
import { MoveLeft, MoveRight, Gem, Smartphone, Globe, Wand2, Sparkle, UploadCloud } from "lucide-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "Common/Components/Modal";
import Dropzone from "react-dropzone"
import Alert from "Common/Components/Alert";
import { auto } from "@popperjs/core";
import { useDispatch } from "react-redux";
import { getAuthHeaders } from "helpers/backend_helper";
import AutocompleteTrainStep from "./AutocompleteTrainStep";
/* import { postAgentAutocomplete } from "helpers/backend_helper"; */

const TrainStep = (props:any) => {

    const {changeTab, newAgentStatus, setNewAgentStatus} = props;

    // defaultModal
    const [defaultModal, setDefaultModal] = useState<boolean>(false);

    const validation: any = useFormik({
        initialValues: {
            company_description: "" || newAgentStatus.company_description,
            name: "" || newAgentStatus.name,
            service_description: "" || newAgentStatus.service_description,
            short_description: "" || newAgentStatus.short_description,
        },
        validationSchema: Yup.object().shape({
            company_description: Yup.string().required("Company description is required"),
            name: Yup.string().required("Name is required"),
            service_description: Yup.string().required("Service description is required"),
            short_description: Yup.string().required("Short description is required"),
        }),
        onSubmit: (values: any) => {
            changeTab();
        },
    });



    const handleAutoCompleteChanges = (values:any) => {
        console.log("handleAutoCompleteChanges", values);
        
        validation.setValues({
            company_description: values.company_description ? values.company_description : "",
            name: values.Name ? values.Name : "",
            service_description: values.service_description ? values.service_description : "",
            short_description: values.short_description ? values.short_description : "",
        });
    }

    // Update the newAgentStatus when the validation changes
    useEffect(() => {
        setNewAgentStatus({
            ...newAgentStatus,
            company_description: validation.values.company_description,
            name: validation.values.name,
            service_description: validation.values.service_description,
            short_description: validation.values.short_description,
        });
    }, [validation.values]);


    return (
        <React.Fragment>
            <div className="flex items-center justify-between mb-4">
                <div>
                    <h5 className="mb-0">Training Informations</h5>
                    <p className="text-sm text-slate-500 mb-0">Please fill in the information below to continue</p>
                </div>

                <AutocompleteTrainStep onSubmit={handleAutoCompleteChanges} validation={validation} />
            </div>
            <form action="#!" onSubmit={(event: any) => {
                event.preventDefault();
                validation.handleSubmit()
            }}>
               
                <div className="grid grid-cols-1 gap-4 ">
                    <div>
                        <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">Agent name <span className="text-red-500">*</span></label>
                        <input 
                            type="text"
                            className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 " 
                            id="name" 
                            placeholder="Enter your agent name..."
                            value={validation.values.name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                        {
                            validation.touched.name && validation.errors.name ?
                                <div className="text-red-500">{validation.errors.name}</div>
                                : null
                        }
                    </div>
                    <div>
                        <label htmlFor="company_description" className="inline-block mb-1 text-sm font-medium">Description of the context or company <span className="text-red-500">*</span></label>
                        <textarea 
                            className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 " 
                            id="company_description" 
                            placeholder="Enter your description of the context or company..."
                            value={validation.values.company_description}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            rows={5}
                        />
                        {
                            validation.touched.company_description && validation.errors.company_description ?
                                <div className="text-red-500">{validation.errors.company_description}</div>
                                : null
                        }
                    </div>
                    <div>
                        <label htmlFor="service_description" className="inline-block mb-1 text-sm font-medium">Description of products <span className="text-red-500">*</span></label>
                        <textarea 
                            className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 " 
                            id="service_description" 
                            placeholder="Enter your description of products..."
                            value={validation.values.service_description}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            rows={5}
                        />
                        {
                            validation.touched.service_description && validation.errors.service_description ?
                                <div className="text-red-500">{validation.errors.service_description}</div>
                                : null
                        }
                    </div>
                    <div>
                        <label htmlFor="short_description" className="inline-block mb-1 text-sm font-medium">Short description <span className="text-red-500">*</span></label>
                        <textarea 
                            className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 " 
                            id="short_description" 
                            placeholder="Enter your short description..."
                            value={validation.values.short_description}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            rows={5}
                        />
                        {
                            validation.touched.short_description && validation.errors.short_description ?
                                <div className="text-red-500">{validation.errors.short_description}</div>
                                : null
                        }
                    </div>
                    
                </div>
                <div className="flex justify-between gap-2 mt-5">
                    <button 
                        disabled={!validation.values.name || !validation.values.company_description || !validation.values.service_description || !validation.values.short_description}
                        type="submit" 
                        data-action="next" 
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                    >
                        <span className="align-middle">Next</span> 
                        <MoveRight className="inline-block h-4 ml-1 rtl:rotate-180" />
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

export default TrainStep;