import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ResetPasswordState {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
    success: boolean;
}

const initialState: ResetPasswordState = {
    token: "",
    email: "",
    password: "",
    password_confirmation: "",
    success: false,
};

const resetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState,
    reducers: {
        resetPasswordSuccess(state: ResetPasswordState, action: PayloadAction<string>) {            
           state.success = true;
        },
        resetPasswordError(state: ResetPasswordState, action: PayloadAction<string | any>) {
            console.error(action.payload);
            state.success = false;
        },
    },
});

export const { resetPasswordSuccess, resetPasswordError } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
