import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from "react-router-dom";
import { addAgentIntent, getAgentLeads } from "slices/thunk";
import Editor from "ckeditor5-custom-build";
import {editorConfiguration} from "./SingleIntent";


const CreateNewIntent = (props:any) => {
    const { currentIntents, setCurrentIntents, handleFetchIntents } = props;
    const inputIntent = useRef<any>(null);
    const inputData = useRef<any>(null);


    const [loading, setLoading] = useState<boolean>(false);
    const [currentIntent, setCurrentIntent] = useState<any>({intent: '', response: ''});

    const { id } = useParams<{ id: string }>();

    const handleCreateNewIntent = async () => {
        setLoading(true);
        if(!id){ return; }

        const response = await addAgentIntent(id, JSON.stringify(currentIntent));

        if(response) {
            setCurrentIntents([...currentIntents, currentIntent])

            // Fetch the intents again
            handleFetchIntents();

            // Reset the form
            inputIntent.current.value = '';
            inputData.current.editor.setData('');
            setCurrentIntent({intent: '', response: ''});
        } else {
            console.log(response.error);
        }

        setLoading(false);
    }
    


    return (
        <div className="card">
        <div className="card-body">
            <h6 className="mb-3 text-15">Create New Intent</h6>

            <label
                htmlFor="intent"
                className="inline-block mb-1 text-sm font-medium"
            >
                Intent <span className="text-red-500">*</span>
            </label>
            <textarea
                ref={inputIntent}
                id="intent"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 mb-3 w-full rounded-md "
                rows={2}
                value={currentIntent.intent}
                onChange={(e) => setCurrentIntent({...currentIntent, intent: e.target.value})}
                placeholder="Enter your intent..."
            />

            <label
                htmlFor="response"
                className="inline-block mb-1 text-sm font-medium"
            >
                Response <span className="text-red-500">*</span>
            </label>
            <CKEditor
                ref={inputData}
                id="response"
                editor={Editor}
                config={editorConfiguration}
                data={currentIntent.response}
                onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    setCurrentIntent({...currentIntent, response: data});
                }}
            />

            <button
                onClick={handleCreateNewIntent}
                disabled={!currentIntent.response || !currentIntent.intent || loading}
                className="mt-3 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
            >
                Create Intent
            </button>
        </div>
    </div>
    );
}

export default CreateNewIntent;
