import React from "react";
import RecentStatistics from "../Components/RecentStatistics";
import moment from "moment";
import WelcomeMessage from "../Components/WelcomeMessage";

interface TableData { label: string; value?: string; link?: boolean }

const OverviewTabs = (props: any) => {
    const {currentAgent, setCurrentAgent} = props;

    const tableData: TableData[] = [
        { label: "👤 Agent name", value: currentAgent.virtual_agent_name },
        { label: "🗓️ Created", value: moment(currentAgent.created_at).fromNow() },
    ];

    return (
        <React.Fragment>
            <WelcomeMessage content={currentAgent.first_message || ""} currentAgent={currentAgent} setCurrentAgent={setCurrentAgent}  />


            <div className="grid grid-cols-1 gap-x-5 2xl:grid-cols-12">
                <div className="2xl:col-span-9">
                    <div className="xl:col-span-9">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="mb-3 text-15">Recent Statistics</h6>
                                <RecentStatistics chartId="recentStatistics" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-3">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Agent information</h6>
                            <div className="overflow-x-auto">
                                <table className="w-full ltr:text-left rtl:ext-right">
                                    <tbody>
                                        {
                                            (tableData || [])?.map((item: TableData, index: number) => (
                                                <tr key={index}>
                                                    <th className="py-2 font-semibold ps-0" scope="row">{item.label}</th>
                                                    <td className="py-2 text-right text-slate-500 dark:text-zink-200">
                                                        {item.value}
                                                    </td>
                                                </tr>

                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>


        </React.Fragment>
    );
}

export default OverviewTabs;
