import React from "react";
import modern from "assets/images/auth-modern.png";

const Modern = () => {
    return (
        <React.Fragment>
            <div className="relative z-10 flex items-center justify-center min-h-screen px-10 text-center grow py-14">
                <div>
                    <img src={modern} alt="" className="block object-cover mx-auto shadow-lg md:max-w-md rounded-xl shadow-custom-800" />
                    <div className="mt-10 text-center">
                        <h3 className="mt-4 mb-3 capitalize text-white">Boost sales with the power of Artificial Intelligence.</h3>
                        <p className="max-w-2xl text-white text-base">Give your sales network and marketing department the power to sell more easily and effectively.</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Modern;
