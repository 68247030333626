import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { changePasswordError, changePasswordSuccess } from "./reducer";
import { POST_CHANGE_PASSWORD } from "helpers/url_helper";
import sendRequest from "helpers/sendRequest";

interface User {
    email: string;
    old_password: string;
    new_password: string;
    new_password_confirmation: string;
}

export const changePassword = (
    user: User,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
  
    try {
        const response = await sendRequest(POST_CHANGE_PASSWORD, {
            method: 'POST', 
            body: JSON.stringify(user),
        }, false);        

        if (response) {
            dispatch(changePasswordSuccess(response));
        }

    } catch (error) {
        dispatch(changePasswordError(error));
    }
};

