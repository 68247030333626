import Collapse from "Common/Components/Collapse/Collapse";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { updateAgentFirstMessage } from "slices/thunk";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from "ckeditor5-custom-build";
import {API_URL} from "../../../helpers/url_helper";
import {getLoggedUser} from "../../../helpers/sendRequest";
import { toast } from "react-toastify";


export const editorConfiguration = {
    toolbar: [  'undo', 'redo',
        '|', 'heading',
        '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
        '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
        '-', // break point
        '|', 'alignment',
        'link', 'uploadImage',
        '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'],
    simpleUpload: {
        uploadUrl: API_URL+'/api/upload',
        withCredentials: false,
        headers: {
            Authorization: `Bearer ${getLoggedUser()?.access_token}`
        }
    }
};


const WelcomeMessage = (props:any) => {
    const { id } = useParams<{ id: string }>();
    const {content, currentAgent, setCurrentAgent} = props;
    const [status, setStatus] = useState<any>({loading: false, error: false, edit: true, success: false});

    const [firstMessage, setFirstMessage] = useState<any>(content ? content : '');


    const handleWelcomeMessageSave = async () => {
        if(!id){ return; }
        setStatus({loading: true, error: false, edit: true, success: false});

        const response = await updateAgentFirstMessage(id, firstMessage);

        if(response.error) {
            setStatus({loading: false, error: true, edit: true, success: false});
            return;
        }

        setCurrentAgent({...currentAgent, first_message: response.first_message})
        setStatus({loading: false, error: false, edit: true, success: true});

        toast.success("Agent updated successfully");

    }


    return (
        <React.Fragment>

            <div>
                <Collapse>
                    <Collapse.Trigger className="flex items-center w-full p-3 mb-2 text-left card collapsible-header group/item">
                        Welcome message
                        <div className="ltr:ml-auto rtl:mr-auto shrink-0">
                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                        </div>
                    </Collapse.Trigger>
                    <Collapse.Content className="collapsible-content card">
                        <div className="card-body">

                            {status.edit ? (
                                <div className="ckeditor-classic text-slate-800 text-15">
                                  
                                    <CKEditor
                                        editor={ Editor }
                                        config={ editorConfiguration }
                                        data={firstMessage || ""}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setFirstMessage(data);
                                        }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="ckeditor-classic text-slate-800 text-15 max-h-60 overflow-auto" dangerouslySetInnerHTML={{ __html: firstMessage || "" }}></div>
                                </>
                            )}

                            <div className="mt-4">
                                {!status.edit ? (
                                    <button disabled={status.loading} onClick={() => setStatus({loading: false, error: false, edit: true, success: false})} type="button" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200">Edit</button>
                                ) : (
                                    <button disabled={status.loading} onClick={handleWelcomeMessageSave} type="button" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200">{status.loading ? "Loading..." : "Save"}</button>
                                )}
                            </div>

                        </div>
                    </Collapse.Content>
                </Collapse>
            </div>


        </React.Fragment>
    );
}

export default WelcomeMessage;
