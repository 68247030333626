import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getAgentActions, getAgentMaterials } from "slices/thunk";
import CreateNewAction from "../Components/CreateNewAction";
import SingleAction from "../Components/SingleAction";
import SingleMaterial from "../Components/SingleMaterial";
import CreateNewMaterial from "../Components/CreateNewMaterial";


const MaterialsTabs = (props: any) => {

    const {currentAgent, setCurrentAgent} = props;

    const [status, setStatus] = useState<any>({loading: false, error: false});

    const [currentMaterials, setCurrentMaterials] = useState<any>(currentAgent.materials ? currentAgent.materials : null);

    const handleFetchAMaterials = async () => {
        const response = await getAgentMaterials(currentAgent.id);        

        if(response) {
            setCurrentMaterials(response);
            setStatus({loading: false, error: false});
        } else {
            setStatus({loading: false, error: true});
        }
    }

    useEffect(() => {
        if(!currentMaterials) {
            setStatus({loading: true, error: false});
            handleFetchAMaterials();
        }
    }, []);

    useEffect(() => {
        setCurrentAgent({...currentAgent, materials: currentMaterials});
    }, [currentMaterials]);

    return (
        <React.Fragment>
            {status.error ? (
               <div>There was an error!</div>
            ) : (
                status.loading ? (
                    [...Array(8)].map((_:any, index: any) => {
                        return (
                            <div key={"skeleton-" + index} className="card">
                                <div className="card-body">
                                    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#ddd">
                                        <p>
                                            <Skeleton  width="100%" height="100%" />
                                        </p>
                                    </SkeletonTheme>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <>
                        {currentMaterials && currentMaterials.length > 0 && currentMaterials.map((action: any, index: number) => (
                            <SingleMaterial currentMaterials={currentMaterials} setCurrentMaterials={setCurrentMaterials} content={action} key={index}  />
                        ))}

                        <CreateNewMaterial currentMaterials={currentMaterials} setCurrentMaterials={setCurrentMaterials}  />
                    </>
                )
            )}

        </React.Fragment>
    );
}

export default MaterialsTabs;
