import Collapse from "Common/Components/Collapse/Collapse";
import { ChevronDown, ChevronUp, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteAgentIntent, updateAgentIntent } from "slices/thunk";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from "ckeditor5-custom-build";
import {API_URL} from "../../../helpers/url_helper";
import {getLoggedUser} from "../../../helpers/sendRequest";
import { toast } from "react-toastify";


export const editorConfiguration = {
    toolbar: [  'undo', 'redo',
        '|', 'heading',
        '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
        '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
        '-', // break point
        '|', 'alignment',
        'link', 'uploadImage',
        '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'],
    simpleUpload: {
        uploadUrl: API_URL+'/api/upload',
        withCredentials: false,
        headers: {
            Authorization: `Bearer ${getLoggedUser()?.access_token}`
        }
    }
};


const SingleIntent = (props:any) => {
    const { id } = useParams<{ id: string }>();
    const {content, currentIntents, setCurrentIntents} = props;
    const [status, setStatus] = useState<any>({loading: false, error: false, edit: true, success: false});

    const [currentIntent, setCurrentIntent] = useState<any>(content);

    useEffect(() => {
        setCurrentIntent(content);        
    }, [content]);

    const handleItentSave = async () => {
        if(!id){ return; }
        setStatus({loading: true, error: false, edit: true, success: false});

        const response = await updateAgentIntent(id , currentIntent.id, JSON.stringify({intent: currentIntent.intent, response: currentIntent.response}));

        if(response.error) {
            setStatus({loading: false, error: true, edit: true, success: false});
            return;
        }

        if(response.intent) {
            setCurrentIntents(
                currentIntents.map((content: any) => {
                    if(content.id === currentIntent.id) {
                        content.intent = currentIntent.intent;
                        content.response = currentIntent.response;
                    }
                    return content;
                })
            )
            setStatus({loading: false, error: false, edit: true, success: true});
            toast.success("Intent updated successfully");
        } else {
            setStatus({loading: false, error: true, edit: true, success: false});
            toast.error("An error occurred while updating the intent");
        }

    }

    const handleItentRemove = async () => {
        if(!id){ return; }

        setStatus({loading: true, error: false, edit: true, success: false});

        const response = await deleteAgentIntent(id , currentIntent.id);

        if(response.error) {
            setStatus({loading: false, error: true, edit: true, success: false});
            toast.error("An error occurred while removing the intent");
            return;
        }

        setCurrentIntents(currentIntents.filter((intent: any) => intent.id !== currentIntent.id));
        setStatus({loading: false, error: false, edit: true, success: false});
        toast.success("Intent removed successfully");
    }


    return (
        <React.Fragment>

            <div>
                <Collapse>
                    <Collapse.Trigger className="flex items-center w-full p-3 mb-2 text-left card collapsible-header group/item">
                        {currentIntent.intent}
                        <div className="ltr:ml-auto rtl:mr-auto shrink-0">
                            <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                            <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                        </div>
                    </Collapse.Trigger>
                    <Collapse.Content className="collapsible-content card">
                        <div className="card-body">

                            {status.edit ? (
                                <div className="ckeditor-classic text-slate-800 text-15">
                                    <textarea
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 mb-3 w-full rounded-md "
                                        rows={2}
                                        defaultValue={currentIntent.intent}
                                        onChange={(e) => setCurrentIntent({...currentIntent, intent: e.target.value})}
                                    />

                                    <CKEditor
                                        editor={ Editor }
                                        config={ editorConfiguration }
                                        data={currentIntent.response ? currentIntent.response : ""}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setCurrentIntent({...currentIntent, response: data});
                                        }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="ckeditor-classic text-slate-800 text-15 max-h-60 overflow-auto" dangerouslySetInnerHTML={{ __html: currentIntent.response }}></div>
                                </>
                            )}

                            <div className="flex gap-2 2xl:justify-end mt-4">
                                {!status.edit ? (
                                    <button disabled={status.loading} onClick={() => setStatus({loading: false, error: false, edit: true, success: false})} type="button" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200">Edit</button>
                                ) : (
                                    <button disabled={status.loading} onClick={handleItentSave} type="button" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200">{status.loading ? "Loading..." : "Save"}</button>
                                )}
                                <button
                                    onClick={handleItentRemove}
                                    disabled={status.loading}
                                    type="button"
                                    className="btn flex items-center justify-center size-[37.5px] p-0 text-red-500 bg-red-100 hover:bg-red-600 hover:text-white focus:text-white focus:bg-red-600 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                                >
                                    <Trash2 className="size-4"></Trash2>
                                </button>
                            </div>

                        </div>
                    </Collapse.Content>
                </Collapse>
            </div>


        </React.Fragment>
    );
}

export default SingleIntent;
