const Messages = [
    "Analyzing the documents...",
    "Processing information...",
    "Generating texts...",
    "Querying artificial intelligence...",
    "Downloading documents..."
];


export default Messages;
