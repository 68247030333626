import Modal from "Common/Components/Modal";
import { AGENT_AVATAR_URL } from "helpers/url_helper";
import { Eye } from "lucide-react";
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import userDummy from "assets/images/users/user-dummy-img.jpg";
import { getAgentSessions } from "slices/thunk";


const SessionsTabs = (props: any) => {
  const { currentAgent, setCurrentAgent } = props;

  const [sessions, setSessions] = useState<any>(
    currentAgent.sessions ? currentAgent.sessions : null
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [session, setSession] = useState<any>(null);

  const modalToggle = (session: any) => (e: any) => {
    e.preventDefault();
    setModal(!modal);
    setSession(session);
  };

  const fetchSessions = async () => {
    setLoading(true);
    const response = await getAgentSessions(currentAgent.id);

    if(response.error) {
        console.log(response.error);
        return;
    } 
    setSessions(response);
    setCurrentAgent({ ...currentAgent, sessions: response });
    setLoading(false);
  };

  useEffect(() => {
    if (!sessions) {
      fetchSessions();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-3 text-15">Sessions</h6>
          <div className="overflow-x-auto">
            <table className="w-full whitespace-nowrap">
              <thead className="ltr:text-left rtl:text-right">
                <tr >
                  <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 ">
                    ID
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 ">
                    Date
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 ">
                    Messages
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 ">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  [...Array(8)].map((_:any, index: any) => {
                    return (
                      <tr key={"skeleton-" + index}>
                        <SkeletonTheme
                          baseColor="#f1f1f1"
                          highlightColor="#ddd"
                        >
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            <Skeleton width="100%" height="100%" />
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            <Skeleton width="100%" height="100%" />
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            <Skeleton width="100%" height="100%" />
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            <Skeleton width="100%" height="100%" />
                          </td>
                        </SkeletonTheme>
                      </tr>
                    );
                  })
                ) : sessions ? (
                  <>
                    {sessions.length > 0 && sessions.map((session: any) => {
                      if(session.data.length === 0){ return null;}
                      return (
                        <tr key={session.id}>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            <a
                              href="!#"
                              onClick={modalToggle(session)}
                              className={session.data.length === 0 ? "text-slate-500" : "text-custom-500"}
                            >
                              #{session.id}
                            </a>
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            {new Date(session.created_at).toLocaleString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                              }
                            )}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            {session.messages}
                          </td>
                          <td className="px-3.5 py-2.5 border-y border-slate-200 ">
                            <button
                              onClick={modalToggle(session)}
                              className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 disabled:cursor-not-allowed disabled:opacity-50"
                              disabled={session.data.length === 0}
                            >
                              <Eye className="size-3"></Eye>
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                    <Modal
                      show={modal}
                      onHide={modalToggle(null)}
                      id="extraLargeModal"
                      modal-center="true"
                      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4 rounded-lg	 overflow-hidden"
                      dialogClassName="w-screen lg:w-[55rem] bg-white shadow rounded-md flex flex-col h-full"
                    >
                      <Modal.Header
                        className="flex items-center justify-between p-4 border-b border-slate-200 "
                        closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 "
                      >
                        <Modal.Title className="text-16">
                          Session: {session && "#" + session.id }
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="bg-body-bg max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                        <SimpleBar>
                          <ul className="flex flex-col gap-5 list-none card-body">
                            {(session && session.data || []).map((item: any, key: number) => (
                                <li
                                    className={`flex chat-message group/item [&.right]:justify-end ${
                                    item.isSender && "right"
                                    }`}
                                    key={key}
                                >
                                    <div className="flex gap-3">
                                    <Link
                                        to="#!"
                                        className="flex items-center self-end justify-center text-sm font-semibold rounded-full size-9 bg-slate-100 text-slate-500 shrink-0 group-[.right]/item:order-3"
                                    >
                                        {item.isSender ? (
                                        <img
                                            src={userDummy}
                                            alt=""
                                            className="object-cover rounded-full h-9"
                                        />
                                        ) : (
                                        <img
                                            src={AGENT_AVATAR_URL + currentAgent.photo}
                                            alt=""
                                            className="object-cover rounded-full h-9"
                                            onError={(e: any) => {e.target.onerror = null; e.target.src = userDummy;}}
                                        />
                                        )}
                                    </Link>
                                    <div className="grow flex flex-col gap-2">
                                      <div
                                        className="whitespace-normal relative p-4 bg-white rounded-md ltr:rounded-bl-none rtl:rounded-br-none shadow-sm 2xl:max-w-lg ltr:group-[.right]/item:rounded-br-none rtl:group-[.right]/item:rounded-bl-none ltr:group-[.right]/item:rounded-bl-md rtl:group-[.right]/item:rounded-br-md "
                                        dangerouslySetInnerHTML={{ __html: item.msg }}
                                      ></div>
                                      <small className="text-xs text-slate-500 group-[.right]/item:text-right">{item.date}</small>
                                    </div>
                                    </div>
                                </li>
                            ))}
                          </ul>
                        </SimpleBar>
                      </Modal.Body>
                    </Modal>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SessionsTabs;
