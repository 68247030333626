import React, { useEffect } from 'react';
import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useDispatch, useSelector } from 'react-redux';
import { getProfile as onGetProfile } from 'slices/thunk';
import {ToastContainer} from "react-toastify";

// Activating fake backend
fakeBackend();

function App() {

    // If the user is authenticated and the profile is not set in Redux, get the profile
    const dispatch = useDispatch<any>();
    const { user } = useSelector((state: any) => state.Profile);
    useEffect(() => {

      if (!user && localStorage.getItem("authUser")) {
        dispatch(onGetProfile());
      }
    }, [user]);

  return (
      <>
          <ToastContainer closeButton={false} limit={5} />
          <RouteIndex />
      </>
  );
}

export default App;
