import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import sendRequest from "helpers/sendRequest";
import { POST_LOGIN } from "helpers/url_helper";
import { getProfile } from "../profile/thunk";
import { toast } from "react-toastify";

interface User {
    email: string;
    password: string;
}

export const loginUser = ( user: User, history: any ): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    try {
        const response = await sendRequest(POST_LOGIN, {
            method: 'POST', 
            body: JSON.stringify(user),
        }, false);        

        localStorage.setItem("authUser", JSON.stringify(response));

        if (response) {
            toast.success("Login successful!");
            dispatch(loginSuccess(response));
            dispatch<any>(getProfile());
            history("/dashboard");
        }

        return response;
    } catch (error) {
        toast.error("Invalid email or password!");
        return error;
    }

};

export const logoutUser = () => async (dispatch: Dispatch) => {
    try {
        localStorage.removeItem("authUser");

        dispatch(logoutSuccess(true));
    } catch (error) {
        dispatch(loginError(error));
    }
}