import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from 'Common/Components/Tab/Tab';
import AgentInfo from './Components/AgentInfo';

import { getAgent } from 'slices/thunk';
import OverviewTabs from './Tabs/OverviewTabs';
import IntentsTabs from './Tabs/IntentsTabs';
import EmbedTabs from './Tabs/EmbedTabs';
import LeadsTabs from './Tabs/LeadsTabs';
import PromptTabs from './Tabs/PromptTabs';
import EditTabs from './Tabs/EditTabs';
import ActionsTabs from './Tabs/ActionsTabs';
import SessionsTabs from './Tabs/SessionsTabs';
import DomainTabs from './Tabs/DomainTabs';
import MaterialsTabs from './Tabs/MaterialsTabs';


const Agent = () => {
    const navigate = useNavigate()

    const { id } = useParams<{ id: string }>();

    const [currentTab, setCurrentTab] = useState<string>('overviewTabs');
    const [currentAgent, setCurrentAgent] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const handleFetchAgent = async (id: string) => {
        const response = await getAgent(id);
        if(response.error) {
            console.log(response.error);
            return;
        }
        if(response.agents) {
            setCurrentAgent(response.agents);
            setLoading(false);
        } else {
            navigate('/agents');
        }
    }

    useEffect(() => {
        if(id && currentAgent === null) {
            setLoading(true);
            handleFetchAgent(id);
        }
    }, [id]);



  return (
    <Fragment>
        <Tab.Container defaultActiveKey="overviewTabs">
            <div className="mt-1 -ml-3 -mr-3 rounded-none card">
                {!loading && currentAgent &&
                    <>
                        <AgentInfo  className="card-body !px-2.5" currentAgent={currentAgent} setCurrentAgent={setCurrentAgent} setCurrentTab={setCurrentTab} />
                        <div className="card-body !px-2.5 !py-0">
                            <div className="flex flex-wrap w-full text-sm font-medium text-center nav-tabs">
                                <a onClick={() => setCurrentTab('overviewTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'overviewTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500 -mb-[1px]'}`}>Overview</a>
                                <a onClick={() => setCurrentTab('intentsTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'intentsTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500 -mb-[1px]'}`}>Intents</a>
                                <a onClick={() => setCurrentTab('actionsTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'actionsTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500  -mb-[1px]'}`}>Actions</a>
                                <a onClick={() => setCurrentTab('materialsTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'materialsTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500  -mb-[1px]'}`}>Materials</a>
                                <a onClick={() => setCurrentTab('leadsTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'leadsTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500  -mb-[1px]'}`}>Leads</a>
                                <a onClick={() => setCurrentTab('sessionsTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'sessionsTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500  -mb-[1px]'}`}>Sessions</a>
                                <a onClick={() => setCurrentTab('domainTab')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'domainTab' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500  -mb-[1px]'}`}>Domain</a>
                                <a onClick={() => setCurrentTab('embedTabs')} href='#!' className={`inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md   border-b border-transparent ${currentTab === 'embedTabs' ? 'text-custom-500 border-b-custom-500' : ' hover:text-custom-500  active:text-custom-500 text-slate-500  -mb-[1px]'}`}>Embed</a>
                            </div>
                        </div>
                    </>
                 }
            </div>
            <div className="tab-content">
                {!loading && currentAgent && (
                    <>
                        {currentTab === 'overviewTabs' && <OverviewTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent}/>}
                        {currentTab === 'intentsTabs' && <IntentsTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent}/>}
                        {currentTab === 'actionsTabs' && <ActionsTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent}/>}
                        {currentTab === 'materialsTabs' && <MaterialsTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent}/>}
                        {currentTab === 'leadsTabs' && <LeadsTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent} />}
                        {currentTab === 'sessionsTabs' && <SessionsTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent} />}
                        {currentTab === 'embedTabs' && <EmbedTabs currentAgent={currentAgent} />}
                        {currentTab === 'promptTab' && <PromptTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent} />}
                        {currentTab === 'domainTab' && <DomainTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent} />}
                        {currentTab === 'editTab' && <EditTabs currentAgent={currentAgent} setCurrentAgent={setCurrentAgent} />}
                    </>
                )}
            </div>
        </Tab.Container>
    </Fragment>
  );
};

export default Agent;
