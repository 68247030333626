import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { TwitterPicker } from "react-color";
import { toast } from "react-toastify";


const EmbedTabs = (props:any) => {
    const { currentAgent } = props;
        
    const domain = currentAgent.domain;
    const [color, setColor] = useState<string>('#681ef7');
    const values = `<script>
        window.ssaConfiguration = {
            domain: "${domain}",
            bubble_color: "${color}"
        }
</script>
<script src="https://app.smartsalesassistant.io/api/widget"></script>`; 

    const valuesWithoutSpaces = `<script>window.ssaConfiguration={domain: "${domain}",bubble_color:'${color}'}</script><script src='https://app.smartsalesassistant.io/api/widget'></script>`


    const [copied, setCopied] = React.useState<boolean>(false);

    const copiedTimeout = useRef<any>(null);

    useEffect(() => {
        copiedTimeout.current = setTimeout(() => {
            setCopied(false);
        }, 5000);
        return () => {
            if (copiedTimeout.current) {
                clearTimeout(copiedTimeout.current);
            }
        };
    }, [copied]);


    return (
        <React.Fragment>

            <div className="card">
                <div className="card-body">
                    <h6 className="mb-3 text-15">Embed Script</h6>
                    <div className="overflow-x-auto">
                        <textarea
                            readOnly
                            id="clipboard"
                            value={values}
                            rows={7}
                            className="mb-3 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        />
                
                        <TwitterPicker 
                            className="mb-3 !shadow-none !border !border-slate-200"
                            width="100%"    
                            triangle="hide"
                            color={color} 
                            onChange={(colors: any) => {
                                setColor(colors.hex);
                                setCopied(false);
                                clearTimeout(copiedTimeout.current);
                            }} 
                        />

            
                        <CopyToClipboard
                            text={valuesWithoutSpaces}
                            onCopy={() => {setCopied(true); toast.success("Copied to clipboard");}}
                        >

                            <button 
                                disabled={copied}
                                className="flex gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500"
                            >
                                Copy to clipboard
                            </button>
                            
                        </CopyToClipboard>
            
                    </div>
                </div>
            </div>
            
            



           
        </React.Fragment>
    );
}

export default EmbedTabs;