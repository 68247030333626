import React, { ChangeEvent, useRef, useState } from "react";
import { AlertTriangle, CheckCircle, ImagePlus, Link, Loader, LoaderIcon, MoreHorizontal, Power, PowerOff, X } from "lucide-react";
import { Dropdown } from "Common/Components/Dropdown";

// IMage
import userDummy from "assets/images/users/user-dummy-img.jpg";
import Modal from "Common/Components/Modal";
import { deleteAgent, duplicateAgent, getAgents, updateAgentImage } from "slices/thunk";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
    shutdownAgent as onShutdownAgent,
    turnonAgent as onTurnOnAgent,
} from 'slices/thunk';
import { AGENT_AVATAR_URL } from "helpers/url_helper";
import { toast } from "react-toastify";


const AgentInfo = ({ className, currentAgent, setCurrentAgent, setCurrentTab }: any) => {
    const navigate = useNavigate()    
    const dispatch = useDispatch<any>();
    const formChangeImage = useRef<any>(null);

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteModalLoading, setDeleteModalLoading] = useState<boolean>(false)

    const [statusLoading, setStatusLoading] = useState<boolean>(false)


    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {

        if(formChangeImage.current) {
            const formData = new FormData();
            if(event.target.files === null) return;
            console.log(event.target.files);
            
            const file = event.target.files[0];
            formData.append('photo', file);

            const response = await updateAgentImage(currentAgent.id, formData);
            
            if(response.error) {
                console.log(response.error);
                return;
            }

            setCurrentAgent({
                ...currentAgent,
                photo: response.photoPath
            });
            
        }

        
    };

    const handleDeleteAgent = async () => {
        setDeleteModalLoading(true);

        const response = await deleteAgent(currentAgent.id);

        if(response.error) {
            console.log(response.error);
            return;
        }

        setDeleteModalLoading(false);
        setDeleteModal(false);
        navigate("/agents");
    }

    const handleStatusTurnOn = async () => {

        setStatusLoading(true);

        dispatch(onTurnOnAgent(currentAgent.id));

        setCurrentAgent({
            ...currentAgent,
            status: 1
        });
        
        setStatusLoading(false);
        
    }

    const handleStatusShutdown = async () => {

        setStatusLoading(true);

        dispatch(onShutdownAgent(currentAgent.id));

        setCurrentAgent({
            ...currentAgent,
            status: 0
        });

        setStatusLoading(false);
    }

    const handleDuplicateAgent = async () => {
        const response = await duplicateAgent(currentAgent.id);

        if(response) {
            dispatch(getAgents());
            toast.success("Agent duplicated successfully");
            navigate("/agents");
        }
    
    }

    // columns
    const Status = ({ item }: any) => {
        switch (item) {
            case 3:
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><AlertTriangle className="size-3 mr-1.5" />Error</span>);
            case 2:
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X className="size-3 mr-1.5" />Offline</span>);
            case 0:
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-blue-100 border-transparent text-blue-500 dark:bg-red-500/20 dark:border-transparent status"><LoaderIcon className="size-3 mr-1.5 animate-spin" />Creating</span>);
            default:
                return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />Online</span>);
        }
    };

    return (
        <React.Fragment>
            <div className={className}>

                {currentAgent ? (
                    <div className="grid grid-cols-1 gap-5 lg:grid-cols-12 2xl:grid-cols-12">
                        <div className="lg:col-span-2 2xl:col-span-1">
                            <div className="relative inline-block rounded-full shadow-md bg-slate-100 profile-user max-w-40	 ">
                                <img 
                                    src={AGENT_AVATAR_URL + currentAgent.photo} 
                                    alt="Agent Avatar" 
                                    className="object-cover w-full h-full border-0 rounded-full img-thumbnail user-profile-image" 
                                    onError={(e: any) => {e.target.onerror = null; e.target.src = userDummy;}}
                                />
                                <div className="absolute bottom-0 flex items-center justify-center size-8 rounded-full ltr:right-0 rtl:left-0 profile-photo-edit">
                                    <form ref={formChangeImage} encType="multipart/form-data">
                                        <input 
                                            name="photo"
                                            id="profile-img-file-input" 
                                            type="file"
                                            className="hidden profile-img-file-input"
                                            onChange={handleImageChange} 
                                        />
                                    </form>
                                   

                                    <label htmlFor="profile-img-file-input" className="text-slate-500 hover:text-white flex items-center justify-center size-8 bg-white rounded-full shadow-lg cursor-pointer dark:bg-zink-600 profile-photo-edit hover:bg-custom-500">
                                        <ImagePlus className="size-4 "></ImagePlus>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-10 2xl:col-span-7">
                            <div className="mb-3 flex items-center gap-4">
                                <h5 className="mb-0">{currentAgent.virtual_agent_name}</h5>
                                <Status item={currentAgent.status} />
                            </div>
                         
                            
                            <p className="text-slate-500 dark:text-zink-200">{currentAgent.agent_description}</p>
                            
                        </div>
                        <div className="lg:col-span-12 2xl:col-span-4">
                            <div className="flex gap-2 2xl:justify-end">
                                <a 
                                    rel="noreferrer" 
                                    target="_blank" 
                                    href={"https://" + currentAgent.domain} 
                                    className="flex items-center justify-center size-[37.5px] p-0 rounded-md text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
                                    >
                                    <Link className="size-4" />
                                </a>
                                <a 
                                    className={`flex items-center justify-center size-[37.5px] p-0 rounded-md ${currentAgent.status === 0 ? 'bg-green-100 text-green-500 hover:bg-green-500 hover:text-white' : 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-white'}`}
                                    href="#!"
                                    onClick={
                                        () => {
                                            !statusLoading &&
                                            currentAgent.status === 0 ? handleStatusTurnOn() : handleStatusShutdown();
                                        }
                                    }
                                >
                                    {statusLoading ? "Loading..." : currentAgent.status === 0 ? <Power className="size-4" /> : <PowerOff className="size-4" />}
                                </a>
                                <button onClick={() => {setCurrentTab('editTab')}} type="button" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Edit Agent</button>

                                <Dropdown className="relative">
                                    <Dropdown.Trigger className="flex items-center justify-center size-[37.5px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20" id="accountSettings" data-bs-toggle="dropdown">
                                        <MoreHorizontal className="size-4"></MoreHorizontal>
                                    </Dropdown.Trigger>
                                    <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white dark:bg-zink-600 rounded-md shadow-md dropdown-menu min-w-[10rem]" aria-labelledby="accountSettings">
                                        <li className="px-3 mb-2 text-sm text-slate-500">
                                            Agent
                                        </li>
                                        <li>
                                            <a 
                                                className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 " 
                                                href="#!"
                                                onClick={() => {handleDuplicateAgent();}}
                                            >
                                                Duplicate Agent
                                            </a>
                                        </li>
                                        <li>
                                            <a 
                                                className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 " 
                                                href="#!"
                                                onClick={() => {setCurrentTab('promptTab');}}
                                            >
                                                Edit Prompt
                                            </a>
                                        </li>
                                        <li>
                                            <a 
                                                data-modal-target="deleteModal" 
                                                className="block px-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-red-500 dropdown-item hover:bg-red-100 hover:text-red-500 focus:text-white focus:bg-red-600 " 
                                                href="#!"
                                                onClick={() => setDeleteModal(true)}
                                            >
                                                Delete agent
                                            </a>
                                        </li>
                                    </Dropdown.Content>
                                </Dropdown>
                            </div>
                        </div>
                       

                        <Modal 
                            onHide={() => {setDeleteModal(false); setDeleteModalLoading(false);}}
                            show={deleteModal} 
                            id="deleteModal" 
                            modal-center="true"
                            className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                            dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
                        >
                            <Modal.Header 
                                className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
                                closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
                            >
                                <Modal.Title className="text-16">Delete Agent</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                                <p className="text-slate-500 dark:text-zink-200 mb-4">They all have something to say beyond the words on the page. They can come across as casual or neutral, exotic or graphic.</p>
                                <button 
                                    disabled={deleteModalLoading}
                                    onClick={() => {handleDeleteAgent();}}
                                    type="button" 
                                    className="w-full text-center text-red-500 bg-red-100 btn hover:text-white hover:bg-red-600 focus:text-white focus:bg-red-600 focus:ring focus:ring-red-100 disabled:bg-slate-100  disabled:border-slate-300  disabled:text-slate-500"
                                >
                                    {deleteModalLoading ? <Loader className="size-4 mx-auto" /> : "Delete Agent"}
                                </button>
                            </Modal.Body>
                        </Modal>
                    </div>
                ) : (
                    "Loading..."
                )}
            </div>

        </React.Fragment>
    );
}

export default AgentInfo;
