import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateAgent } from "slices/thunk";
import * as Yup from "yup";

const EditTabs = (props:any) => {
    const { currentAgent, setCurrentAgent } = props;
    const [loading, setLoading] = useState<any>(false);
    const [success, setSuccess] = useState<any>(false);

    useEffect(() => {
        if(success){
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        }
    }, [success]);

    const validation: any = useFormik({
        initialValues: {
            company_description: "" || currentAgent.company_description,
            name: "" || currentAgent.virtual_agent_name,
            service_description: "" || currentAgent.service_product_description,
            short_description: "" || currentAgent.agent_description,
        },
        validationSchema: Yup.object().shape({
            company_description: Yup.string().required("Company description is required"),
            name: Yup.string().required("Name is required"),
            service_description: Yup.string().required("Service description is required"),
            short_description: Yup.string().required("Short description is required"),
        }),
        onSubmit: (values: any) => {
            setLoading(true);
            handleUpdateAgent(values);
        },
    });

    const handleUpdateAgent = async (values:any) => {
        const response = await updateAgent(currentAgent.id, JSON.stringify(values));

        if(response.error) {
            toast.error("An error occurred while updating the agent");
            console.log(response.error);
            return;
        }

        setCurrentAgent({
            ...currentAgent,
            company_description: values.company_description,
            name: values.name,
            service_description: values.service_description,
            short_description: values.short_description,
        });
        setSuccess(true);
        setLoading(false);

    }

    return (
        <React.Fragment>

            <div className="card">
                <div className="card-body">
                    <h6 className="mb-3 text-15">Edit Agent</h6>
                    <div className="overflow-x-auto">
                        <form action="#!" onSubmit={(event: any) => {
                            event.preventDefault();
                            validation.handleSubmit()
                        }}>
                            <div className="grid grid-cols-1 gap-4 ">
                                <div>
                                    <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">Agent name <span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        id="name"
                                        placeholder="Enter your agent name..."
                                        value={validation.values.name}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                    />
                                    {
                                        validation.touched.name && validation.errors.name ?
                                            <div className="text-red-500">{validation.errors.name}</div>
                                            : null
                                    }
                                </div>
                                <div>
                                    <label htmlFor="company_description" className="inline-block mb-1 text-sm font-medium">Description of the context or company <span className="text-red-500">*</span></label>
                                    <textarea
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        id="company_description"
                                        placeholder="Enter your description of the context or company..."
                                        value={validation.values.company_description}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        rows={5}
                                    />
                                    {
                                        validation.touched.company_description && validation.errors.company_description ?
                                            <div className="text-red-500">{validation.errors.company_description}</div>
                                            : null
                                    }
                                </div>
                                <div>
                                    <label htmlFor="service_description" className="inline-block mb-1 text-sm font-medium">Description of products <span className="text-red-500">*</span></label>
                                    <textarea
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        id="service_description"
                                        placeholder="Enter your description of products..."
                                        value={validation.values.service_description}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        rows={5}
                                    />
                                    {
                                        validation.touched.service_description && validation.errors.service_description ?
                                            <div className="text-red-500">{validation.errors.service_description}</div>
                                            : null
                                    }
                                </div>
                                <div>
                                    <label htmlFor="short_description" className="inline-block mb-1 text-sm font-medium">Short description <span className="text-red-500">*</span></label>
                                    <textarea
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        id="short_description"
                                        placeholder="Enter your short description..."
                                        value={validation.values.short_description}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        rows={5}
                                    />
                                    {
                                        validation.touched.short_description && validation.errors.short_description ?
                                            <div className="text-red-500">{validation.errors.short_description}</div>
                                            : null
                                    }
                                </div>

                            </div>

                            <button
                                type="submit"
                                disabled={!validation.values.name || !validation.values.company_description || !validation.values.service_description || !validation.values.short_description || loading}
                                className={`mt-4 text-white transition-all duration-200 ease-linear btn ${success ? 'bg-green-500' : 'bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20'} disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200`}
                            >
                            Update Agent
                            </button>

                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default EditTabs;
