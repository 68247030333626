import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// login
import LoginReducer from "./auth/login/reducer";

// forgot-password
import ForgotPasswordReducer from "./auth/forgot-password/reducer";

// reset-password
import ResetPasswordReducer from "./auth/reset-password/reducer";

// reset-password
import ChangePasswordReducer from "./auth/change-password/reducer";

// register
import RegisterReducer from "./auth/register/reducer";

// userProfile
import ProfileReducer from "./auth/profile/reducer";

// Agents
import AgentsReducer from "./agents/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    ForgotPassword: ForgotPasswordReducer,
    Register: RegisterReducer,
    Profile: ProfileReducer,
    Agents: AgentsReducer,
    ResetPassword: ResetPasswordReducer,
    ChangePassword: ChangePasswordReducer,
});


export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;