import { useEffect, useState } from "react";
import Messages from "./Messages";



const LoadingTexts = () => {
   
    const loadTextList = Messages;
    const [loadingText, setLoadingText] = useState<any>(loadTextList[0] + "...");
   
    useEffect(() => {
        if(loadingText){
            setTimeout(() => {
                setLoadingText(loadTextList[Math.floor(Math.random() * loadTextList.length)] + "...");
            },5000);
        }
    }, [loadingText]);

    return (
        <p className="text-15">{loadingText}</p>
    );
};
  
 export default LoadingTexts;