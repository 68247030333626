import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addAgentAction, updateAgentMaterials } from "slices/thunk";
import Editor from "ckeditor5-custom-build";
import {editorConfiguration} from "./SingleIntent";
import { toast } from "react-toastify";


const CreateNewMaterial = (props:any) => {
    const { currentMaterials, setCurrentMaterials } = props;


    const [loading, setLoading] = useState<boolean>(false);
    const randomId = Math.random().toString(36).substring(7);
    const [currentMaterial, setCurrentMaterial] = useState<any>({label: '', type: null, content: '', id: randomId});

    const { id } = useParams<{ id: string }>();

    const handleCreateNewMaterial = async () => {
        setLoading(true);
        if(!id){ return; }

        const newMaterialsList = Array.isArray(currentMaterials) ? [...currentMaterials, currentMaterial] : [currentMaterial];

        const {data, error} = await updateAgentMaterials(id, JSON.stringify(newMaterialsList));        

        if(error) {
            console.log(error);
            toast.error("An error occurred while creating the material");
            setLoading(false);
            return;
        }

        toast.success("Material created successfully");
        setCurrentMaterials(newMaterialsList);
        setCurrentMaterial({label: '', type: null , content: '', id: Math.random().toString(36).substring(7)});
        setLoading(false);        

    }



    return (
        <div className="card">
        <div className="card-body">
            <h6 className="mb-3 text-15">Create New Material</h6>

            <form action="#!" onSubmit={(event: any) => {
                event.preventDefault();
                handleCreateNewMaterial()
            }}>

                <div className="grid grid-cols-1 gap-2 flex-1" >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">

                        <div>
                            <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">Label <span className="text-red-500">*</span></label>
                            <input type="text"
                                placeholder="Title of the button"
                                value={currentMaterial.label}
                                className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                onChange={e => {
                                    setCurrentMaterial({...currentMaterial, label: e.target.value});
                                }}
                            />
                        </div>

                        <div>
                            <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">Type <span className="text-red-500">*</span></label>
                            <select
                                className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                onChange={e => {
                                    setCurrentMaterial({...currentMaterial, type: e.target.value});
                                }}
                                value={currentMaterial.type}
                            >
                                <option value='' hidden>Select type</option>
                                <option value={0}>URL</option>
                            </select>
                        </div>

                    </div>

                    {currentMaterial.type == 0 && (
                        <div>
                            <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">URL <span className="text-red-500">*</span></label>
                            <input
                                type="url"
                                placeholder="https://example.com"
                                value={currentMaterial.content}
                                className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                onChange={e => {
                                    setCurrentMaterial({...currentMaterial, content: e.target.value});
                                }}
                            />
                        </div>
                    )}


                </div>

                <button
                    type="submit"
                    disabled={!currentMaterial.label || !currentMaterial.type || !currentMaterial.content || loading}
                    className="mt-3 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                >
                    Create Material
                </button>
            </form>
        </div>
    </div>
    );
}

export default CreateNewMaterial;
