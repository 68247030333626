import { resetPasswordError, resetPasswordSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import sendRequest from "helpers/sendRequest";
import { POST_RESET_PASSWORD } from "helpers/url_helper";

interface User {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}

export const resetPasswordUser = (
    user: User,
    history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    
    try {
        const response = await sendRequest(POST_RESET_PASSWORD, {
            method: 'POST', 
            body: JSON.stringify(user),
        }, false);        

        if (response) {
            dispatch(resetPasswordSuccess(response));
        }

    } catch (error) {
        dispatch(resetPasswordError(error));
    }
};

