import React, { useEffect, useRef, useState } from "react";
import { Smartphone, Globe, Sparkle, UploadCloud } from "lucide-react";
import { useFormik } from "formik";
import Modal from "Common/Components/Modal";
import Dropzone from "react-dropzone"
import Alert from "Common/Components/Alert";
import { useDispatch } from "react-redux";

import {
    autocompleteUrlAgent as onAutocompleteUrlAgent,
} from 'slices/thunk';
import LoadingTexts from "Common/Components/LoadingTexts";

const AutocompleteTrainStep = (props:any) => {
    const dispatch = useDispatch<any>();


    const {changeTab, validation, onSubmit} = props;


    const [status, setStatus] = useState<any>({loading: false, complete: false, error: false});

    // defaultModal
    const [defaultModal, setDefaultModal] = useState<boolean>(false);
    const defaultToggle = () => setDefaultModal(!defaultModal);


    const [autocompleteType, setAutocompleteType] = useState<any>(null);
    const autocomplete: any = useFormik({
        initialValues: {
            type: "",
            content: "",
        },
        onSubmit: async (values: any) => {
            setStatus({loading: true, complete: false, error: false});

            if (autocomplete.values.type === "url") {
                const response = await onAutocompleteUrlAgent(autocomplete.values.content);
                setStatus({loading: false, complete: true, error: false});
                onSubmit(response)
                setDefaultModal(false);
            } else {

            }


        },
    });


    const [selectedBorderFiles, setSelectedBorderFiles] = React.useState<any>([])
    const handleAcceptedBorderFiles = (files: any) => {
        setSelectedBorderFiles(files[0])
        autocomplete.values.content = files[0]
    }


    return (
        <>

            <button
                data-modal-target="defaultModal"
                type="button"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                onClick={defaultToggle}
            >
                <Sparkle className="inline-block h-4 mr-1 rtl:rotate-180" /> 
                <span className="align-middle">Autocomplete</span>
            </button>

            <Modal
                show={defaultModal}
                onHide={defaultToggle}
                id="defaultModal"
                modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
            >
                <Modal.Header
                    className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
                >
                    <Modal.Title className="text-16">Autocomplete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">

                    {!status.loading ? (
                        <>
                            <p className="text-slate-500 dark:text-zink-200 mb-4">They all have something to say beyond the words on the page. They can come across as casual or neutral, exotic or graphic.</p>

                            <form
                                action="#!"
                                onSubmit={(event: any) => {
                                    event.preventDefault();
                                    autocomplete.handleSubmit();
                                    return false;
                                }}
                                encType="multipart/form-data"
                            >

                                <label htmlFor="" className="inline-block mb-3 text-sm font-medium">Type <span className="text-red-500">*</span></label>
                                <div className="grid grid-cols-2 gap-3 mb-4 ">

                                    <div style={{opacity: "0.7", pointerEvents:"none"}}>
                                        <input onChange={() => {setAutocompleteType("file"); setSelectedBorderFiles(null); autocomplete.values.type = "file"; autocomplete.values.content = ""}} id="autocompleteTypeFile" name="autocompleteType" className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500" type="radio" value="" />
                                        <label htmlFor="autocompleteTypeFile" className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500">
                                            <Smartphone className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
                                            <span className="block font-medium text-15">File (coming soon ⏳)</span>
                                        </label>
                                    </div>

                                    <div>
                                        <input onChange={() => {setAutocompleteType("url"); setSelectedBorderFiles(null); autocomplete.values.type = "url"; autocomplete.values.content = ""}} id="autocompleteTypeUrl" name="autocompleteType" className="hidden size-4 border rounded-full appearance-none peer/business bg-slate-100 border-slate-200 dark:bg-zink-600 checked:bg-custom-500 dark:checked:bg-custom-500 checked:border-custom-500 dark:checked:border-custom-500" type="radio" value="" />
                                        <label htmlFor="autocompleteTypeUrl" className="block px-3 text-center border rounded-md cursor-pointer border-slate-200 py-7 text-slate-500 dark:text-zink-200 peer-checked/business:border-custom-500 dark:peer-checked/business:border-custom-500 peer-checked/business:text-custom-500 dark:peer-checked/business:text-custom-500">
                                            <Globe className="block size-8 mx-auto mb-3 stroke-1 fill-slate-100 dark:fill-zink-600" />
                                            <span className="block font-medium text-15">Website</span>
                                        </label>
                                    </div>
                                </div>

                                {autocompleteType === "url" &&
                                    <div>
                                        <label htmlFor="content" className="inline-block mb-1 text-sm font-medium">Website Url <span className="text-red-500">*</span></label>
                                        <input
                                            type="url"
                                            id="content"
                                            className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                            placeholder="www.google.com"
                                            onChange={ (e) => {
                                                    setSelectedBorderFiles(e.target.value);
                                                    autocomplete.values.content = e.target.value;
                                                }
                                            }
                                            value={autocomplete.values.type === "url" ? autocomplete.values.content : ""}
                                            required
                                        />
                                    </div>
                                }

                                {autocompleteType === "file" &&
                                    <div>
                                        <label htmlFor="positionInput" className="inline-block mb-1 text-sm font-medium">Upload File <span className="text-red-500">*</span></label>
                                        {selectedBorderFiles && selectedBorderFiles.size > 0 ? (
                                            <Alert className="relative p-3 text-sm text-green-500 border border-transparent rounded-md bg-green-50 dark:bg-green-400/20">
                                                <Alert.Bold className="font-bold">
                                                    <div className="flex items-center justify-between">
                                                        File Uploaded
                                                        <button
                                                            type="button"
                                                            className="p-0 text-red-500"
                                                            onClick={() => {
                                                                setSelectedBorderFiles(null);
                                                                autocomplete.values.content = ""
                                                            }}
                                                        >
                                                            <i className="ri-delete-bin-6-line"></i>
                                                        </button>
                                                    </div>
                                                </Alert.Bold>
                                            </Alert>
                                        ) : (
                                            <>
                                                <Dropzone
                                                    onDrop={(acceptedFiles: any) => {
                                                        handleAcceptedBorderFiles(acceptedFiles)
                                                    }}
                                                >
                                                    {({ getRootProps, getInputProps }: any) => (
                                                        <div
                                                            className="w-full py-5 text-lg text-center dz-message needsclick border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 border rounded-md"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div className="mb-3">
                                                                <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                                                            </div>

                                                            <h5 className="mb-0 font-normal text-slate-500 text-15">Drag and drop your files or <a href="#!">browse</a> your files</h5>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </>

                                        )}


                                    </div>
                                }

                                <button
                                    disabled={!selectedBorderFiles || status.loading}
                                    type="submit"
                                    className={`px-4 py-2.5 text-15 text-white btn ${status.complete ? 'bg-green-500' : 'bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20'} mt-4 w-full disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200`}
                                >
                                    Autocomplete
                                </button>

                                {status.complete && <div className="text-green-500 mt-2 block w-full text-center">Autocomplete complete!</div>}


                            </form>
                        </>
                    ) : (

                        <div className="text-center">
                            <div className="inline-block size-10 border-2 rounded-full animate-spin border-l-transparent border-custom-500 mb-5"></div>
                            <LoadingTexts />
                        </div>
                    )}




                </Modal.Body>
            </Modal>

        </>
    );
}

export default AutocompleteTrainStep;
