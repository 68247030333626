// dashboard
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import AgentsList from "pages/Agents/List";
import AgentsNew from "pages/Agents/New";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import UserProfile from "pages/UserProfile/UserProfile";
import Agent from "pages/Agent";


interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: AgentsList },
  { path: "/dashboard", component: AgentsList },

  // profile
  { path: "/user-profile", component: UserProfile },

  // Agents
  { path: "/agents", component: AgentsList },
  { path: "/agent/:id", component: Agent},
  { path: "/agents/new", component: AgentsNew },


];

const publicRoutes = [

  // authentication
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/logout", component: Logout },
  /* { path: "/register", component: Register }, */

]

export { authProtectedRoutes, publicRoutes };
