import Collapse from "Common/Components/Collapse/Collapse";
import { ChevronDown, ChevronUp, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { deleteAgentAction, updateAgentAction, updateAgentMaterials } from "slices/thunk";
import {editorConfiguration} from "./SingleIntent";
import { toast } from "react-toastify";
import { json } from "stream/consumers";

const SingleMaterial = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const { content, currentMaterials, setCurrentMaterials } = props;
  const [status, setStatus] = useState<any>({
    loading: false,
    error: false,
    success: false,
  });

  const [currentMaterial, setCurrentMaterial] = useState<any>(content);
  

  const handleMaterialSave = async () => {
    if (!id) {
      return;
    }
    setStatus({ loading: true, error: false, success: false });

    setCurrentMaterials(
      currentMaterials.map((material: any) => {
        if (material.id === currentMaterial.id) {
          material.type = currentMaterial.type;
          material.label = currentMaterial.label;
          material.content = currentMaterial.content;
        }
        return material;
      })
    );

    console.log("update currentMaterials", currentMaterials);
    
    const {data, error} = await updateAgentMaterials(
      id,
      JSON.stringify(currentMaterials)
    );    

    if (error) {
      toast.error("An error occurred while updating the material");
      setStatus({ loading: false, error: true, success: false });
      return;
    }

    toast.success("Material updated successfully");
    setStatus({ loading: false, error: false, success: true });

   
  };

  const handleMaterialRemove = async () => {
    if (!id) {
      return;
    }

    setStatus({ loading: true, error: false, success: false });

    const newMaterialsList = currentMaterials.filter(
      (material: any) => material.id !== currentMaterial.id
    );

    const {data, error} = await updateAgentMaterials(id, JSON.stringify(newMaterialsList));
    console.log(data, error);
    

    if (error) {
      toast.error("An error occurred while removing the material");
      setStatus({ loading: false, error: true, success: false });
      return;
    }

    setCurrentMaterials(newMaterialsList);
    setStatus({ loading: false, error: false, success: false });
    toast.success("Material removed successfully");
  };

  return (
    <React.Fragment>
      <div>
        <Collapse>
          <Collapse.Trigger className="flex items-center w-full p-3 mb-2 text-left card collapsible-header group/item">
            {content.label}
            <div className="ltr:ml-auto rtl:mr-auto shrink-0">
              <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
              <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
            </div>
          </Collapse.Trigger>
          <Collapse.Content className="collapsible-content card">
            <div className="card-body">
              <div className=" text-slate-800 text-15">
                <div className=" text-slate-800 text-15">
                  <div className="grid grid-cols-1 gap-2 flex-1">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <div>
                        <label
                          htmlFor="name"
                          className="inline-block mb-1 text-sm font-medium"
                        >
                          Label <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Title of the button"
                          defaultValue={content.label}
                          className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                          onChange={(e) => {
                            setCurrentMaterial({
                              ...currentMaterial,
                              label: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="name"
                          className="inline-block mb-1 text-sm font-medium"
                        >
                          Type <span className="text-red-500">*</span>
                        </label>
                        <select
                          className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                          onChange={(e) => {
                            setCurrentMaterial({
                              ...currentMaterial,
                              type: e.target.value,
                            });
                          }}
                          value={content.type}
                        >
                          <option value={0}>URL</option>
                        </select>
                      </div>
                    </div>

                    {currentMaterial.type == 0 && (
                      <div>
                        <label
                          htmlFor="name"
                          className="inline-block mb-1 text-sm font-medium"
                        >
                          URL <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="url"
                          placeholder="https://example.com"
                          defaultValue={content.content}
                          className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                          onChange={(e) => {
                            setCurrentMaterial({
                              ...currentMaterial,
                              content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>

              <div className="flex gap-2 2xl:justify-end mt-4">
                <button
                  disabled={status.loading}
                  onClick={handleMaterialSave}
                  type="button"
                  className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                >
                  {status.loading ? "Loading..." : "Save"}
                </button>

                <button
                  onClick={handleMaterialRemove}
                  disabled={status.loading}
                  type="button"
                  className="btn flex items-center justify-center size-[37.5px] p-0 text-red-500 bg-red-100 hover:bg-red-600 hover:text-white focus:text-white focus:bg-red-600 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                >
                  <Trash2 className="size-4"></Trash2>
                </button>
              </div>
            </div>
          </Collapse.Content>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default SingleMaterial;
