import Collapse from "Common/Components/Collapse/Collapse";
import { ChevronDown, ChevronUp, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { deleteAgentAction, updateAgentAction } from "slices/thunk";
import {editorConfiguration} from "./SingleIntent";
import { toast } from "react-toastify";

const SingleAction = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const { content, currentActions, setCurrentActions } = props;
  const [status, setStatus] = useState<any>({
    loading: false,
    error: false,
    success: false,
  });

  const [currentAction, setCurrentAction] = useState<any>(content);

  const handleActionSave = async () => {
    if (!id) {
      return;
    }
    setStatus({ loading: true, error: false, success: false });

    const response = await updateAgentAction(
      id,
      currentAction.id,
      JSON.stringify({
        type: currentAction.type,
        label: currentAction.label,
        content: currentAction.content,
      })
    );

    if (response.error) {
      setStatus({ loading: false, error: true, success: false });
      return;
    }

    if (response.action) {
      setCurrentActions(
        currentActions.map((action: any) => {
          if (action.id === currentAction.id) {
            action.type = currentAction.type;
            action.label = currentAction.label;
            action.content = currentAction.content;
          }
          return content;
        })
      );
      setStatus({ loading: false, error: false, success: true });
      toast.success("Action updated successfully");
    } else {
      setStatus({ loading: false, error: true, success: false });
      toast.error("An error occurred while updating the action");
    }
  };

  const handleActionRemove = async () => {
    if (!id) {
      return;
    }

    setStatus({ loading: true, error: false, success: false });

    const response = await deleteAgentAction(id, content.id);

    if (response.error) {
      setStatus({ loading: false, error: true, success: false });
      toast.error("An error occurred while removing the action");
      return;
    }

    setCurrentActions(
      currentActions.filter((action: any) => action.id !== content.id)
    );
    setStatus({ loading: false, error: false, success: false });
    toast.success("Action removed successfully");
  };

  return (
    <React.Fragment>
      <div>
        <Collapse>
          <Collapse.Trigger className="flex items-center w-full p-3 mb-2 text-left card collapsible-header group/item">
            {content.label}
            <div className="ltr:ml-auto rtl:mr-auto shrink-0">
              <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
              <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
            </div>
          </Collapse.Trigger>
          <Collapse.Content className="collapsible-content card">
            <div className="card-body">
              <div className=" text-slate-800 text-15">
                <div className=" text-slate-800 text-15">
                  <div className="grid grid-cols-1 gap-2 flex-1">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <div>
                        <label
                          htmlFor="name"
                          className="inline-block mb-1 text-sm font-medium"
                        >
                          Label <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Title of the button"
                          defaultValue={content.label}
                          className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                          onChange={(e) => {
                            setCurrentAction({
                              ...currentAction,
                              label: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="name"
                          className="inline-block mb-1 text-sm font-medium"
                        >
                          Type <span className="text-red-500">*</span>
                        </label>
                        <select
                          className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                          onChange={(e) => {
                            setCurrentAction({
                              ...currentAction,
                              type: e.target.value,
                            });
                          }}
                          value={content.type}
                        >
                          <option value={0}>URL</option>
                          <option value={1}>Message</option>
                        </select>
                      </div>
                    </div>

                    {currentAction.type == 0 && (
                      <div>
                        <label
                          htmlFor="name"
                          className="inline-block mb-1 text-sm font-medium"
                        >
                          URL <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="url"
                          placeholder="https://example.com"
                          defaultValue={content.content}
                          className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                          onChange={(e) => {
                            setCurrentAction({
                              ...currentAction,
                              content: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}


                    {currentAction.type == 1 && (
                        <div>
                          <label
                              htmlFor="name"
                              className="inline-block mb-1 text-sm font-medium"
                          >
                            Message <span className="text-red-500">*</span>
                          </label>
                          <CKEditor
                              id="response"
                              editor={Editor}
                              config={editorConfiguration}
                              data={currentAction.content}
                              onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                setCurrentAction({
                                  ...currentAction,
                                  content: data,
                                });
                              }}
                          />
                        </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex gap-2 2xl:justify-end mt-4">
                <button
                  disabled={status.loading}
                  onClick={handleActionSave}
                  type="button"
                  className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                >
                  {status.loading ? "Loading..." : "Save"}
                </button>

                <button
                  onClick={handleActionRemove}
                  disabled={status.loading}
                  type="button"
                  className="btn flex items-center justify-center size-[37.5px] p-0 text-red-500 bg-red-100 hover:bg-red-600 hover:text-white focus:text-white focus:bg-red-600 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                >
                  <Trash2 className="size-4"></Trash2>
                </button>
              </div>
            </div>
          </Collapse.Content>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default SingleAction;
