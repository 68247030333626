import { createSlice } from "@reduxjs/toolkit";
import {
    addAgent,
    autocompleteUrlAgent,
    getAgents,
    shutdownAgent,
    turnonAgent,
    /* addAgent, */
    /* updateAgent, */
    /* deleteAgent, */
} from './thunk';

export const initialState = {
    list: null,
    error: null
};

const AgentsSlice = createSlice({
    name: 'Agents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Agents
        builder.addCase(getAgents.fulfilled, (state: any, action: any) => {
            state.list = action.payload.agents;
        });
        builder.addCase(getAgents.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        // Add Agent
        builder.addCase(addAgent.fulfilled, (state: any, action: any) => {
            state.list.unshift(action.payload);
        });
        builder.addCase(addAgent.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        // Shutdown Agent
        builder.addCase(shutdownAgent.fulfilled, (state: any, action: any) => {
            state.list = null;
        });

        // Turn On Agent
        builder.addCase(turnonAgent.fulfilled, (state: any, action: any) => {
            state.list = null;
        });
        builder.addCase(turnonAgent.rejected, (state: any, action: any) => {
            return action.payload.error || null;
        });

        

       /*  builder.addCase(getAgents.fulfilled, (state: any, action: any) => {
            state = action.payload.agents;
        });
        builder.addCase(getAgents.rejected, (state: any, action: any) => {
            state = action.payload.error || null;
        });
        builder.addCase(addAgent.fulfilled, (state: any, action: any) => {
            state.unshift(action.payload);
        });
        builder.addCase(addAgent.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateAgent.fulfilled, (state: any, action: any) => {
            state = state.map((list: any) =>
                list.id === action.payload.id
                    ? { ...list, ...action.payload }
                    : list
            );
        });
        builder.addCase(updateAgent.rejected, (state: any, action: any) => {
            state = action.payload.error || null;
        });
        builder.addCase(deleteAgent.fulfilled, (state: any, action: any) => {
            state = state.list.filter(
                (list: any) => list.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteAgent.rejected, (state: any, action: any) => {
            state = action.payload.error || null;
        });  */
      
    }
});

export default AgentsSlice.reducer;