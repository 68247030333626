import React, { useState } from "react";
import { toast } from "react-toastify";
import { updateAgentPrompt } from "slices/thunk";


const PromptTabs = (props:any) => {
    const { currentAgent, setCurrentAgent } = props;
    const [loading, setLoading] = useState<any>(false);
        
    const [prompt, setPrompt] = useState<any>(currentAgent.prompt ? currentAgent.prompt : "");
   
    const handleUpdatePrompt = async () => {
        setLoading(true);
      
        const response = await updateAgentPrompt(currentAgent.id, JSON.stringify({prompt: prompt}));

        if(response.error) {
            console.log(response.error);
            return;
        }
        
        setPrompt(response.prompt); 
        setCurrentAgent({...currentAgent, prompt: response.prompt});
        toast.success("Prompt updated successfully!");
        setLoading(false);

    }


   

  
    return (
        <React.Fragment>

            <div className="card">
                <div className="card-body">
                    <h6 className="mb-3 text-15">Edit Prompt</h6>
                    <div className="overflow-x-auto">

                        <textarea
                            readOnly={loading}
                            id="clipboard"
                            defaultValue={prompt}
                            onChange={(e) => {
                                setPrompt(e.target.value);
                            }}
                            rows={20}
                            className="mb-3 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        />
                
                        <button 
                            onClick={handleUpdatePrompt}
                            disabled={loading}
                            className="flex gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500"
                        >
                           Update Prompt
                        </button>
            
                    </div>
                </div>
            </div>
                       
        </React.Fragment>
    );
}

export default PromptTabs;