import React, { useRef, ChangeEvent } from "react";

import userDummy from "assets/images/users/user-dummy-img.jpg";

//redux
import { useSelector, useDispatch } from "react-redux";

import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import ChangePasswordTabs from "./ChangePasswordTabs";
import ProfileTabs from "./ProfileTabs";
import { RootState } from "slices";
import { ImagePlus } from "lucide-react";
import { updateUserImage } from "slices/thunk";

import { getProfile as onGetProfile } from 'slices/thunk';

const UserProfile = () => {

  const formChangeImage = useRef<any>(null);
  //meta title
  document.title = "Profile | SSA";

  const dispatch = useDispatch<any>();

  const currentUser: any = useSelector((state: RootState) => {
    return state.Profile.user
  });

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {

    if(formChangeImage.current) {
      try {
        const formData = new FormData();
        if(event.target.files === null) return;
        
        const file = event.target.files[0];
        formData.append('photo', file);
  
        await updateUserImage(formData);
  
        // Reset current user
        dispatch(onGetProfile());
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="Edit" pageTitle="Profile" />

        <div className="row">
          <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-1">
          
            <div className="card">
              <div className="card-body">
                <div className="flex gap-3">
                  <div className="relative inline-block size-20 rounded-full shadow-md bg-slate-100 profile-user ">
                      <img 
                        src={currentUser.profile_photo_url} 
                        alt="User Avatar" 
                        className="object-cover w-full h-full border-0 rounded-full img-thumbnail user-profile-image" 
                        onError={(e: any) => {e.target.onerror = null; e.target.src = userDummy;}}
                      />
                      <div className="absolute bottom-0 flex items-center justify-center size-8 rounded-full ltr:right-0 rtl:left-0 profile-photo-edit">
                          <form ref={formChangeImage} encType="multipart/form-data">
                            <input 
                                name="photo"
                                id="profile-img-file-input" 
                                type="file"
                                className="hidden profile-img-file-input"
                                onChange={(event) => handleImageChange(event)} 
                            />
                          </form>
                          <label htmlFor="profile-img-file-input" className="flex items-center justify-center size-8 bg-white rounded-full shadow-lg cursor-pointer dark:bg-zink-600 profile-photo-edit hover:bg-custom-500 text-slate-500 hover:text-white">
                              <ImagePlus className="size-4 "></ImagePlus>
                          </label>
                      </div>
                  </div>
                  
                  <div className="text-slate-500 dark:text-zink-200">
                    <h5 className="text-slate-500">{currentUser.name}</h5>
                    <p className="mb-1">{currentUser.email}</p>
                    <p className="mb-0">Id no: #{currentUser.id}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <ProfileTabs />

        <ChangePasswordTabs />



      </div>
    </React.Fragment >
  );
};

export default withRouter(UserProfile);