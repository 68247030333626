import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { addAgentAction } from "slices/thunk";
import Editor from "ckeditor5-custom-build";
import {editorConfiguration} from "./SingleIntent";
import { toast } from "react-toastify";


const CreateNewAction = (props:any) => {
    const { currentActions, setCurrentActions } = props;


    const [loading, setLoading] = useState<boolean>(false);
    const [currentAction, setCurrentAction] = useState<any>({label: '', type: null, content: ''});

    const { id } = useParams<{ id: string }>();

    const handleCreateNewAction = async () => {
        setLoading(true);
        if(!id){ return; }

        const response = await addAgentAction(id, JSON.stringify(currentAction));

        if(response) {
            setCurrentActions([...currentActions, currentAction])
            setCurrentAction({label: '', type: null, content: ''});
            toast.success("Action created successfully");

        } else {
            console.log(response.error);
        }

        setLoading(false);
    }


    return (
        <div className="card">
        <div className="card-body">
            <h6 className="mb-3 text-15">Create New Action</h6>

            <form action="#!" onSubmit={(event: any) => {
                event.preventDefault();
                handleCreateNewAction()
            }}>

                <div className="grid grid-cols-1 gap-2 flex-1" >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">

                        <div>
                            <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">Label <span className="text-red-500">*</span></label>
                            <input type="text"
                                placeholder="Title of the button"
                                defaultValue={currentAction.label}
                                className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                onChange={e => {
                                    setCurrentAction({...currentAction, label: e.target.value});
                                }}
                            />
                        </div>

                        <div>
                            <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">Type <span className="text-red-500">*</span></label>
                            <select
                                className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                onChange={e => {
                                    setCurrentAction({...currentAction, type: e.target.value});
                                }}
                                value={currentAction.type}
                            >
                                <option hidden value="">Select type</option>
                                <option value={0}>URL</option>
                                <option value={1}>Message</option>
                            </select>
                        </div>

                    </div>

                    {currentAction.type == 0 && (
                        <div>
                            <label htmlFor="name" className="inline-block mb-1 text-sm font-medium">URL <span className="text-red-500">*</span></label>
                            <input
                                type="url"
                                placeholder="https://example.com"
                                defaultValue={currentAction.url}
                                className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                onChange={e => {
                                    setCurrentAction({...currentAction, content: e.target.value});
                                }}
                            />
                        </div>
                    )}


                    {currentAction.type == 1 && (
                        <div>
                            <label
                                htmlFor="name"
                                className="inline-block mb-1 text-sm font-medium"
                            >
                                Message <span className="text-red-500">*</span>
                            </label>
                            <CKEditor
                                id="response"
                                editor={Editor}
                                config={editorConfiguration}
                                data={currentAction.content}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setCurrentAction({
                                        ...currentAction,
                                        content: data,
                                    });
                                }}
                            />
                        </div>
                    )}

                </div>

                <button
                    type="submit"
                    disabled={!currentAction.label || !currentAction.type || !currentAction.content || loading}
                    className="mt-3 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:text-slate-500  disabled:bg-slate-200 disabled:border-slate-200"
                >
                    Create Action
                </button>

            </form>
        </div>
    </div>
    );
}

export default CreateNewAction;
