
import sendRequest from "helpers/sendRequest";
import { GET_PROFILE, UPDATE_PROFILE_IMAGE } from "helpers/url_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface User {
    username: string;
    idx: number;
}

/* export const editProfile = (user: User
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    try {
        let response: any;
        if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            response = await postFakeProfile(user)

        } else if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

            const fireBaseBackend = getFirebaseBackend();
            response = await fireBaseBackend.editProfileAPI(
                user.username,
                user.idx
            );
        }

        if (response) {
            dispatch(profileSuccess(response))
        }

    } catch (error) {
        dispatch(profileFailed(error))
    }
} */

export const getProfile = createAsyncThunk("auth/getProfile", async () => {
    try {
        const response = await sendRequest(GET_PROFILE, { method: 'GET', }, true);
        
        return response;
    } catch (error) {
        return error;
    }
});


export const updateUserImage = async (content: any) => {
    try {
        const response = await sendRequest(UPDATE_PROFILE_IMAGE, { method: 'POST', body: content }, true, {});
        const data = await response;
        return data;
    } catch (error) {
        return error;
    }
} 
