import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProfile } from "./thunk";

interface ProfileState {
    user: string;
    error: string;
    success: boolean;
}

const initialState: ProfileState = {
    user: "",
    error: "",
    success: false
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        profileSuccess(state: ProfileState, action: PayloadAction<string>) {
            state.user = action.payload;
            state.success = true;
        },
        profileFailed(state: ProfileState, action: PayloadAction<string | any>) {
            state.error = action.payload;
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        // Get Profile
        builder.addCase(getProfile.fulfilled, (state: any, action: any) => {
            state.user = action.payload;
        });
        builder.addCase(getProfile.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        
    }
})

export const { profileSuccess, profileFailed } = profileSlice.actions;
export default profileSlice.reducer;