import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAgentLeads } from "slices/thunk";


const LeadsTabs = (props:any) => {
    const { currentAgent, setCurrentAgent } = props;

    const { id } = useParams<{ id: string }>();
  

    const [leads, setLeads] = useState<any>(currentAgent.leads ? currentAgent.leads : null);

    const [loading, setLoading] = useState<boolean>(false);

    const fetchLeads = async () => {
        setLoading(true);
        const response = await getAgentLeads(currentAgent.id);

        if(response.error) {
            toast.error("An error occurred while fetching the leads");
            console.log(response.error);
            return;
        }
        
        setLeads(response); 
        setCurrentAgent({...currentAgent, leads: response}); 
        setLoading(false);
    };

    useEffect(() => {
        if(!leads){
            fetchLeads()
        } 
    }, []);

    return (
        <React.Fragment>
            
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-3 text-15">Leads</h6>
                    <div className="overflow-x-auto">
                        <table className="w-full whitespace-nowrap">
                            <thead className="ltr:text-left rtl:text-right">
                                <tr>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">ID</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Date</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Name</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    loading ? (
                                        [...Array(8)].map((_:any, index: any) => {
                                            return (
                                                <tr key={"skeleton-" + index}>
                                                    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#ddd">
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                    </SkeletonTheme>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                                leads ? (
                                                    leads.map((lead: any, index: any) => {
                                                        return (
                                                            <tr key={lead.id}>
                                                                <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{lead.id}</td>
                                                                <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{new Date(lead.created_at).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric'})}</td>
                                                                <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{lead.lead.name}</td>
                                                                <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><a href={"mailto:" + lead.lead.email} className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">{lead.lead.email}</a></td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    null
                                                )
                                            )
                                            
                                        }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </React.Fragment>
    );
}

export default LeadsTabs;