import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getAgentActions } from "slices/thunk";
import CreateNewAction from "../Components/CreateNewAction";
import SingleAction from "../Components/SingleAction";
import { toast } from "react-toastify";


const ActionsTabs = (props: any) => {

    const {currentAgent, setCurrentAgent} = props;

    const [status, setStatus] = useState<any>({loading: false, error: false});

    const [currentActions, setCurrentActions] = useState<any>(currentAgent.actions ? currentAgent.actions : null);

    const handleFetchActions = async () => {
        const response = await getAgentActions(currentAgent.id);

        if(response) {
            setCurrentActions(response.ctas);
            setStatus({loading: false, error: false});
        } else {
            toast.error("An error occurred while fetching the actions");
            setStatus({loading: false, error: true});
        }

        setStatus({loading: false, error: false});
    }

    useEffect(() => {
        if(!currentActions) {
            setStatus({loading: true, error: false});
            handleFetchActions();
        }
    }, []);

    useEffect(() => {
        setCurrentAgent({...currentAgent, actions: currentActions});
    }, [currentActions]);

    return (
        <React.Fragment>
            {status.error ? (
               <div>There was an error!</div>
            ) : (
                status.loading ? (
                    [...Array(8)].map((_:any, index: any) => {
                        return (
                            <div key={"skeleton-" + index} className="card">
                                <div className="card-body">
                                    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#ddd">
                                        <p>
                                            <Skeleton  width="100%" height="100%" />
                                        </p>
                                    </SkeletonTheme>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <>
                        {currentActions && currentActions.length > 0 && currentActions.map((action: any, index: number) => (
                            <SingleAction currentActions={currentActions} setCurrentActions={setCurrentActions} content={action} key={index}  />
                        ))}

                        <CreateNewAction currentActions={currentActions} setCurrentActions={setCurrentActions}  />
                    </>
                )
            )}

        </React.Fragment>
    );
}

export default ActionsTabs;
