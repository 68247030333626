import React, { useState } from "react";

// Formik Validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";
import { useDispatch } from "react-redux";
import { changePassword } from "slices/thunk";

const ChangePasswordTabs = () => {

    const dispatch = useDispatch<any>();


    const [data, setData] = useState({
        email: 'test@example.com',
        old_password: 'Password10!',
        new_password: '',
        new_password_confirmation: '',
    });

    const validation = useFormic({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            email: data.email,
            old_password: data.old_password,
            new_password: data.new_password,
            new_password_confirmation: data.new_password_confirmation,
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            old_password: Yup.string().required("Please Enter Your Old Password"),
            new_password: Yup.string().required("Please Enter Your New Password"),
            new_password_confirmation: Yup.string().required("Please Enter Your New Password Confirmation"),
        }),
        onSubmit: (values) => {
            console.log(values);
            
          dispatch(changePassword(values));
        }
      });


    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Changes Password</h6>
                    <form 
                        action="#!"
                        onSubmit={(e) => {
                            e.preventDefault();
                            console.log(validation.values);
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                            <div className="xl:col-span-4">
                                <label htmlFor="oldPassword" className="inline-block mb-2 text-base font-medium">Old Password*</label>
                                <div className="relative">
                                    <input
                                        required
                                        type="password"
                                        id="oldPassword"
                                        name="old_password"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter old password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.old_password || ""} />
                                    {validation.touched.old_password && validation.errors.old_password ? (                                    
                                        <div id="oldPassword-error" className="mt-1 text-sm text-red-500">{validation.errors.old_password}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="xl:col-span-4">
                                <label htmlFor="newPassword" className="inline-block mb-2 text-base font-medium">New Password*</label>
                                <div className="relative">
                                    <input
                                        required
                                        type="password"
                                        id="newPassword"
                                        name="new_password"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter new password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.new_password || ""} />
                                    {validation.touched.new_password && validation.errors.new_password ? (
                                        <div id="newPassword-error" className="mt-1 text-sm text-red-500">{validation.errors.new_password}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="xl:col-span-4">
                                <label htmlFor="new_password_confirmation" className="inline-block mb-2 text-base font-medium">Confirm New Password*</label>
                                <div className="relative">
                                    <input
                                        required
                                        type="password"
                                        id="inputValue"
                                        name="new_password_confirmation"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter new password confirmation"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.new_password_confirmation || ""} />
                                    {validation.touched.new_password_confirmation && validation.errors.new_password_confirmation ? (
                                        <div id="inputValue-error" className="mt-1 text-sm text-red-500">{validation.errors.new_password_confirmation}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex items-center xl:col-span-6">
                                <a href="/forgot-password" className="font-semibold underline transition-all duration-150 ease-linear text-slate-500 hover:text-custom-500 ">Forgot Password ?</a>
                            </div>
                            <div className="flex justify-end xl:col-span-6">
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100">Change Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>

    );
}

export default ChangePasswordTabs;