/* import { postRefreshToken } from "./postRefreshToken";
 */

// Gets the logged in user data from local session
import {API_URL} from "./url_helper";

export const getLoggedUser = () => {
    const user = localStorage.getItem("authUser");
    if (user) return JSON.parse(user);
    return null;
};

export default async function sendRequest(path:string, options = {}, authentication = true, h:any = {'Content-Type': 'application/json', 'Accept': 'application/json',}) {

    const headers = {
        ...h,
        ...(authentication && { 'Authorization': `Bearer ${getLoggedUser()?.access_token}`, }),
    };

    const response = await fetch(`${getRootUrl()}${path}`, {
        method: 'POST',
        headers,
        credentials: 'same-origin',
        ...options,
    });

    if (response.status === 401) {
        try {
            /* const refresh = await postRefreshToken();

            localStorage.setItem('access_token', refresh.access_token);
            state.session.accessToken = refresh.access_token;

            // Retry the request
            console.log('refresh sendRequest');
            sendRequest(path, options, authentication); */

        } catch (error) {
            console.log(error);

            /* localStorage.removeItem('access_token');
            state.session.accessToken = '';
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            } */

        }

    }


    // Verifica se la risposta è vuota prima di tentare il parsing JSON
    const data = response.headers.get('content-type')?.includes('application/json') ? await response.json() : null;

    if (data && data.error) {        
        throw new Error(data.error);
    }

    // Se data è null, allora torna solamente true
    if (!data) return true;

    return data;
}


function getRootUrl() {
    /*const dev = process.env.NODE_ENV !== 'production';
    const ROOT_URL = dev
        ? process.env.NEXT_PUBLIC_URL_APP
        : process.env.NEXT_PUBLIC_PRODUCTION_URL_APP;

    return ROOT_URL;*/

    return API_URL;
}

