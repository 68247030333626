import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateAgentDomain } from "slices/thunk";


const DomainTabs = (props:any) => {
    const inputDomain = useRef<any>(null);
    const { currentAgent, setCurrentAgent } = props;
    const [loading, setLoading] = useState<any>(false);
        
    const [domain, setDomain] = useState<any>(currentAgent.domain ? currentAgent.domain.split('.')[0] : "");
    const domainSuffix = currentAgent.domain ? '.' + currentAgent.domain.split('.').slice(1).join('.') : "";
   
    const handleUpdateDomain = async () => {
        setLoading(true);
        
        try {
            // Send the request to update the domain
            const response = await updateAgentDomain(currentAgent.id, JSON.stringify({domain: domain}));
            
            // Update the domain in the currentAgent state
            if(response){
                toast.success("Domain updated successfully!");
            } else {
                toast.error("Domain not updated!");
            }
        } catch (error:any) {
            console.log(currentAgent.domain);
            
            inputDomain.current.value = currentAgent.domain ? currentAgent.domain.split('.')[0] : "";
            toast.error(error.message); 
            return;
        } finally {
            setLoading(false);
        }   

    }

  
    return (
        <React.Fragment>

            <div className="card">
                <div className="card-body">
                    <h6 className="mb-3 text-15">Edit Domain</h6>
                    <div className="overflow-x-auto">
                        

                        <div className="relative">
                            <input
                                ref={inputDomain}
                                type="url"
                                readOnly={loading}
                                id="clipboard"
                                defaultValue={domain}
                                onChange={(e) => {
                                    setDomain(e.target.value);
                                }}
                                className="mb-3 form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300  disabled:text-slate-500 placeholder:text-slate-400 pr-24"
                            />
                            <span className="absolute top-0 right-0 pl-2 h-full w-fit bg-slate-100 flex items-center px-4 ">{domainSuffix}</span>
                        </div>
                
                        <button 
                            onClick={handleUpdateDomain}
                            disabled={loading}
                            className="flex gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500"
                        >
                           Update Domain
                        </button>
            
                    </div>
                </div>
            </div>
                       
        </React.Fragment>
    );
}

export default DomainTabs;