import { forgotPasswordError, forgotPasswordSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import sendRequest from "helpers/sendRequest";
import { POST_FORGOT_PASSWORD } from "helpers/url_helper";

interface User {
    email: string;
}

export const forgotPasswordUser = (
    user: User,
    history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {

    try {
        const response = await sendRequest(POST_FORGOT_PASSWORD, {
            method: 'POST', 
            body: JSON.stringify(user),
        }, false);        

        if (response) {
            dispatch(forgotPasswordSuccess(response));
        }

    } catch (error) {
        dispatch(forgotPasswordError(error));
    }
};

