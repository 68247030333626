import React, { useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Rocket, User2 } from "lucide-react";
import { Nav } from "Common/Components/Tab/Nav";
import TrainStep from "./Tabs/TrainStep";
import MaterialStep from "./Tabs/MaterialStep";

import { createAgent } from 'slices/thunk';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const New = () => {

    const navigate = useNavigate()    

    const [activeTab, setActiveTab] = useState("train");

    const [newAgent, setNewAgent] = useState({

        // Step 1
        company_description: "",
        name: "",
        service_description: "",
        short_description: "",

        // Step 2
        materials: [],

    });

    const handleSubmitNewAgent = async () => {

        const {data, error} = await createAgent(newAgent);
        
        if(error) {
            toast.error("Error creating agent");
            return;
        }
        
        toast.success("Agent created successfully");
        navigate("/agents");
      
    };



    return (
        <React.Fragment>
            <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
                <BreadCrumb title="New" pageTitle="Agents" />

                <div className="grid grid-cols-1 2xl:grid-cols-12">
                    <div className="xl:col-span-12">
                        <div className="card">
                            <div className="card-body">

                               

                                {activeTab === "train" && <TrainStep newAgentStatus={newAgent} setNewAgentStatus={setNewAgent} changeTab={() => handleSubmitNewAgent()} />}
                                {/* {activeTab === "materials" && <MaterialStep newAgentStatus={newAgent} setNewAgentStatus={setNewAgent} changeTab={() => handleSubmitNewAgent()} prevTab={() => {setActiveTab("train")}}  />} */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default New;