import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ForgotPasswordState {
    user: string;
    error: string;
    success: boolean;
}

const initialState: ForgotPasswordState = {
    user: "",
    error: "",
    success: false,
};

const forgotPasswordSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        forgotPasswordSuccess(state: ForgotPasswordState, action: PayloadAction<string>) {            
            state.user = action.payload;
            state.success = true;
        },
        forgotPasswordError(state: ForgotPasswordState, action: PayloadAction<string | any>) {
            state.error = action.payload;
            state.success = false;
        },
    },
});

export const { forgotPasswordSuccess, forgotPasswordError } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
