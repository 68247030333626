import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import TableContainer from 'Common/TableContainer';
import Select from 'react-select';

// Icons
import { Search, Trash2, Plus, FileEdit, CheckCircle, X, AlertTriangle, LoaderIcon } from 'lucide-react';
import DeleteModal from 'Common/DeleteModal';

// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    getAgents as onGetAgents,
    deleteAgent,
} from 'slices/thunk';
import filterDataBySearch from 'Common/filterDataBySearch';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const List = () => {
    const dispatch = useDispatch<any>();


    const selectDataList = createSelector(
        (state: any) => state.Agents,
        (agent) => ({
            agentList: agent.list,
        })
    );

    const { agentList } = useSelector(selectDataList);
    const [agent, setAgent] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [eventData, setEventData] = useState<any>();

    // Get Data
    useEffect(() => {
        dispatch(onGetAgents());
        setInterval(() => {
            dispatch(onGetAgents());            
        }, 30000);
    }, [dispatch]);

    useEffect(() => {
        setAgent(agentList);
        setTimeout(() => {setLoading(false)}, 500);
    }, [agentList]);

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = async () => {
        if (eventData) {
            setLoading(true);

            const response = await deleteAgent(eventData.id);

            if(response) {
                setAgent(
                    agent.filter((item: any) => item.id !== eventData.id)
                )
            }

            setLoading(false);
            setDeleteModal(false);
        }
    };
    //

    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            img: (eventData && eventData.img) || '',
            userId: (eventData && eventData.userId) || '',
            name: (eventData && eventData.name) || '',
            designation: (eventData && eventData.designation) || '',
            location: (eventData && eventData.location) || '',
            email: (eventData && eventData.email) || '',
            phoneNumber: (eventData && eventData.phoneNumber) || '',
            joiningDate: (eventData && eventData.joiningDate) || '',
            status: (eventData && eventData.status) || '',
        },
        validationSchema: Yup.object({
            img: Yup.string().required("Please Add Image"),
            name: Yup.string().required("Please Enter Name"),
            designation: Yup.string().required("Please Enter Designation"),
            location: Yup.string().required("Please Enter Location"),
            email: Yup.string().required("Please Enter Email"),
            phoneNumber: Yup.string().required("Please Enter Phone Number"),
            joiningDate: Yup.string().required("Please Enter Joining Date"),
            status: Yup.string().required("Please Enter Status")
        }),

        onSubmit: (values) => {
            console.log(values);
        },
    });

  

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['virtual_agent_name'];
        filterDataBySearch(agentList, search, keysToSearch, setAgent);
    };

    // columns
    const Status = ({ item }: any) => {
        switch (item) {
            case 3:
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><AlertTriangle className="size-3 mr-1.5" />Error</span>);
            case 2:
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X className="size-3 mr-1.5" />Offline</span>);
            case 0:
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-blue-100 border-transparent text-blue-500 dark:bg-red-500/20 dark:border-transparent status"><LoaderIcon className="size-3 mr-1.5 animate-spin" />Creating</span>);
            default:
                return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />Online</span>);
        }
    };

    const columns = useMemo(() => [
        {
            header: "Name",
            accessorKey: "virtual_agent_name",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <div className="flex items-center gap-2">
                    <div className="grow">
                        <h6 className="mb-0"><Link to={"/agent/" + cell.row.original.id} className="name">{cell.getValue()}</Link></h6>
                    </div>
                </div>
            ),
        },
        {
            header: "Sessions",
            accessorKey: "session_count",
            enableColumnFilter: false
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <Status item={cell.getValue()} />
            ),
        },
        {
            header: "URL",
            accessorKey: "domain",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <a title={cell.getValue()} target='_blank' rel="noreferrer" href={"https://" + cell.getValue()} className="url text-custom-500">{cell.getValue()}</a>
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className="flex flex-none items-center justify-start gap-2">
                    <Link
                        to={"/agent/" + cell.row.original.id}
                        className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500"
                    >
                        <FileEdit className="size-3"></FileEdit>
                    </Link>
                    <button
                        onClick={() => {
                            const orderData = cell.row.original;
                            onClickDelete(orderData);
                        }}
                        className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500"
                    >
                        <Trash2 className="size-3"></Trash2>
                    </button>
                </div>
            ),
        }
    ], []
    );

    const options = [
        { value: 'Select Status', label: 'Select Status' },
        { value: 1, label: 'Online' },
        { value: 0, label: 'Offline' },
    ];

    const handleChange = (selectedOption: any) => {
        setLoading(true);
        if (selectedOption.value === 'Select Status' || selectedOption.value === 'Hidden') {
            setAgent(agentList);
        } else {
            const filteredAgents = agentList.filter((data: any) => data.status === selectedOption.value);
            setAgent(filteredAgents);
        }
        setTimeout(() => {setLoading(false)}, 500);
    };

    return (
        <React.Fragment>
            <BreadCrumb title='List' pageTitle='Agents' />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="agentsTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Your Assistants</h6>
                                <div className="shrink-0">
                                    <Link to={"/agents/new"} className="text-white btn font-bold gap-4 bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 " ><Plus className="inline-block size-4" /> <span className="align-middle">Add Agent</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                            <form action="#!">
                                <div className="gap-5 xl:flex xl:items-center">
                                    <div className="relative mb-4 xl:mb-0 flex-1">
                                        <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 " placeholder="Search for agent's name..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                        <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                                    </div>
                                    <div className="">
                                        <Select
                                            className="border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                            options={options}
                                            isSearchable={false}
                                            defaultValue={options[0]}
                                            onChange={(event: any) => handleChange(event)}
                                            id="choices-single-default"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            {loading ? (
                                 <table className="w-full whitespace-nowrap">
                                    <thead className="ltr:text-left rtl:text-right">
                                        <tr>
                                            <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></th>
                                            <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></th>
                                            <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></th>
                                            <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(8)].map((_:any, index: any) => {
                                            return (
                                                <tr key={"skeleton-" + index}>
                                                    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#ddd">
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"><Skeleton width="100%" height="100%" /></td>
                                                    </SkeletonTheme>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                agent && agent.length > 0 ?
                                    <TableContainer
                                        isPagination={false}
                                        columns={(columns || [])}
                                        data={(agent || [])}
                                        customPageSize={10000}
                                        divclassName="-mx-5 -mb-5 overflow-x-auto text-sm"
                                        tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                        theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                        thclassName="text-sm px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                        tdclassName="text-sm px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    />
                                :
                                    (
                                        <div className="noresult">
                                            <div className="py-6 text-center">
                                                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                                <p className="mb-0 text-slate-500 dark:text-zink-200">We searched your agents but could not find any agents for your search.</p>
                                            </div>
                                        </div>
                                    )

                            )}

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default List;
