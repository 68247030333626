import React from "react";

// Formik validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

// Image
import logoSmall from "assets/images/logo-sm.png";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { Link } from "react-router-dom";
import ModernImage from "../AuthenticationInner/ModernImage";
import Modern from "../AuthenticationInner/Modern";
import { resetPasswordUser } from "slices/auth/reset-password/thunk";

const ResetPassword = (props: any) => {

    document.title = "Reset Password | Smart Sales Assistance";

    const url = new URL(window.location.href);
    const token = url.pathname.split('/').pop();
    const email = url.searchParams.get('email');

    // Se non c'è il token o l'email, reindirizzo alla pagina di login
    if (!token || !email) {
        props.router.navigate('/login');
    }

    const dispatch = useDispatch<any>();

    const [status, setStatus] = React.useState({success: false, error: false});

    const validation: any = useFormic({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            email: email || '',
            token: token || '',
            password: '',
            password_confirmation: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
            password_confirmation: Yup.string().required("Please Enter Your Password Confirmation"),
        }),
        onSubmit: (values: any) => {
            console.log(values);
            if (values.password !== values.password_confirmation) {
                setStatus({success: false, error: true});
                return;
            } else {
                setStatus({success: true, error: false});
                dispatch(resetPasswordUser(values, props.router.navigate));
            }
        }
    });



    return (
        <React.Fragment>
            <div className="relative flex flex-col w-full overflow-hidden xl:flex-row to-custom-800 bg-gradient-to-r from-custom-900 dark:to-custom-900 dark:from-custom-950">
                <ModernImage />
                <div className="min-h-[calc(100vh_-_theme('spacing.4')_*_2)] mx-3 xl:w-[40rem] shrink-0 px-10 py-14 flex items-center justify-center m-4 bg-white rounded z-10 relative xl:mx-4">
                    <div className="flex flex-col w-full h-full">
                        <div className="my-auto">
                            <Link to="/">
                                <img src={logoSmall} alt="" className="w-8 mx-auto" />
                            </Link>

                            <div className="mt-8 text-center">
                                <h4 className="mb-1">Set a New Password</h4>
                                <p className="text-slate-500 dark:text-zink-200">Your new password should be distinct from any of your prior passwords</p>
                            </div>

                            <form className="mt-10" id="resetPasswordForm"
                                onSubmit={(event: any) => {
                                    event.preventDefault();
                                    validation.handleSubmit();                                    
                                    return false;
                                }}>
                                {status.success && <div className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50" id="successAlert">
                                    Password reset successfully.
                                </div>}
                                {status.error && <div className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50" id="successAlert">
                                    Attention, the two passwords must match.
                                </div>}
                                <div className="mb-3">
                                    <label htmlFor="password" className="inline-block mb-2 text-base font-medium">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password || ""} />
                                    {validation.touched.password && validation.errors.password ? (
                                        <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="passwordConfirmInput" className="inline-block mb-2 text-base font-medium">Confirm Password</label>
                                    <input
                                        type="password"
                                        id="passwordConfirmInput"
                                        name="password_confirmation"
                                        className="form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500 "
                                        placeholder="Enter password confirmation"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password_confirmation || ""} />
                                    {validation.touched.password_confirmation && validation.errors.password_confirmation ? (                                    
                                        <div id="passwordConfirmInput-error" className="mt-1 text-sm text-red-500">{validation.errors.password_confirmation}</div>
                                    ) : null}
                                </div>
                             
                                <div className="mt-0">
                                    <button type="submit" className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 ">Reset Password</button>
                                </div>

                                <div className="mt-10 text-center">
                                    <p className="mb-0 text-slate-500 dark:text-zink-200">Hold on, I've got my password... <Link to="/login" className="font-semibold underline transition-all duration-150 ease-linear text-slate-500 hover:text-custom-500 "> Click here</Link> </p>
                                </div>
                            </form>
                        </div>
                        <div className="mt-5">
                            <p className="mb-0 text-center text-base text-slate-500 dark:text-zink-200">© {new Date().getFullYear()} SSA. Crafted with <i className="text-red-500 ri-heart-fill"></i> by <a target="_blank" href="http://baasbox.com" className="underline transition-all duration-200 ease-linear text-slate-800 dark:text-zink-50 hover:text-custom-500">Baasbox</a></p>
                        </div>
                    </div>
                </div>
                <Modern />
            </div>
        </React.Fragment>
    );
}

export default withRouter(ResetPassword);