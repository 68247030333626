import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ChangePasswordState {
    error: string;
    success: boolean;
}

const initialState: ChangePasswordState = {
    error: "",
    success: false,
};

const changePasswordSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        changePasswordSuccess(state: ChangePasswordState, action: PayloadAction<string>) {            
            state.success = true;
        },
        changePasswordError(state: ChangePasswordState, action: PayloadAction<string | any>) {
            state.error = action.payload;
            state.success = false;
        },
    },
});

export const { changePasswordSuccess, changePasswordError } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
